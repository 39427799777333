import axios from 'axios'
import store from '..'

const apiUrl = process.env.VUE_APP_API_URL

const filters = () => ({
  Status: null,
  Inn: null,
  LegalForm: null
})

const pagination = () => ({
  currentPage: 1,
  pageSize: 20, 
  total: 0,
})

const state = () => ({
  tableComponentKey: 0,
  clients: [], 
  allClients: [],
  clientsIsLoading: true,
  filters: filters(),
  pagination: pagination()
})

const getters = {
  getClientById: (state) => (clientId) => {
    return state.allClients.find((client) => client.Id === clientId)
  }
}

const mutations = {
  setAllClients(state, clients) {
    state.allClients = clients
  },
  increaseTableComponentKey(state) {
    state.tableComponentKey += 1
  },
  setPagination(state, value) {
    state.pagination = value
  },
  clearPagination(state) {
    state.pagination = pagination()
  },
  clearFilters(state) {
    state.filters = filters()
  },
  setFilter(state, { filterName, filterValue }) {
    state.filters[filterName] = filterValue
  },
  setClientsIsLoading(state, value) {
    state.clientsIsLoading = value
  },
  setClients(state, value) {
    state.clients = value
    state.clientsIsLoading = false
  },
  addClient(state, value) {
    const index = state.clients.findIndex((client) => client.Inn === value.Inn) >= 0
    if (!index) {
      state.clients.push(value)
    }
  }
}

const actions = {
  fetchAllClients(ctx) {
    const payload = { url: "/webapi/v1/clients/getclients", data: {} }
    const token = localStorage.getItem('token') || localStorage.getItem('client_token')
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/business-account/${store.state.Auth.activeAccount.id}/ord`,
        method: 'POST',
        data: payload,
        headers: { 'Authorization': `Bearer ${ token }` }
      })
        .then(resp => {
          ctx.commit('setAllClients', resp.data.data)
          resolve(resp.data.data)
        })
        .catch(err => {
          reject(err.response)
        })
      })
  },
  createClient(ctx, data) {
    const payload = { url: "/webapi/v1/clients/createclient", data }
    const token = localStorage.getItem('token') || localStorage.getItem('client_token')
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/business-account/${store.state.Auth.activeAccount.id}/ord`,
        method: 'POST',
        data: payload,
        headers: { 'Authorization': `Bearer ${ token }` }
      })
        .then(resp => {
          if(resp.data.success) {
            resolve(resp.data.data)
            ctx.commit('addClient', resp.data.data)
          } else if (!resp.data.success) {
            reject(resp.data.errors)
          }
        })
        .catch(err => {
          reject(err.response)
        })
      })
  },
  getClients(ctx, data) {
    ctx.commit('setClientsIsLoading', true)
    const payload = { url: "/webapi/v1/clients/getclients", data }
    const token = localStorage.getItem('token') || localStorage.getItem('client_token')
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/business-account/${store.state.Auth.activeAccount.id}/ord`,
        method: 'POST',
        data: payload,
        headers: { 'Authorization': `Bearer ${ token }` }
      })
        .then(resp => {
          ctx.commit('setClients', resp.data.data)
          ctx.commit('setClientsIsLoading', false)
          resolve(resp.data.data)
        })
        .catch(err => {
          reject(err.response)
        })
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};