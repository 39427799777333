let withSpaces = {
    methods: {
        numberWithSpaces(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        numberWithSpacesCeil(x) {
            const y = Math.floor(+x)
            return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        numberWithSpacesFixed(x, step) {
            const y = (+x).toFixed(step)
            return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        numberWithSpacesCeilFix2(x) {
            const y = Math.floor(+x);
            const z = y.toFixed(2);
            return z.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        numberWithSpacesFix2(x) {
            if (typeof x !== "number") { 
                x = Number(x)
            }
            return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        numberWithSpacesFixedFormatted(x, step) {
            x = (+x).toFixed(step);
            x = +x;
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        numberWithSpacesCeilOrigin(x) {
            const y = +x
            return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
                 
    }
}

export default withSpaces;
