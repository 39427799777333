<template>
  <div class="documents">
    <div class="container">
      <div class="documents__header">
        <div class="documents__title" :class="{'locale-usa': locale_glob==='USA'}">
          {{ $t('documents.documents') }}
        </div>
        <div v-if="locale_glob ==='USA' && role !='user' && (role === 'manager'|| role === 'finance')" class="documents__add-new" @click="addDocument">
         + {{ $t('add_document') }}
        </div>
      </div>
      <div class="documents__table" :class="{'locale-usa': locale_glob==='USA'}">
        <el-table
          v-if="locale_glob==='RU'"
          :data="documentsList"
          style="width: 100%"
        
        >
          <el-table-column prop="number" :label="$t('documents.file_number')" />
          <el-table-column prop="type">
            <template slot="header">
              <span class="name-label">{{ $t('documents.file_type') }}</span>
            </template>
            <template slot-scope="scope">
              <span :style="`color: ${getColor(scope.row.file_type)}`">
                {{  getFileType(scope.row.file_type) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="doc">
            <template slot="header">
              <span class="name-label">{{ $t('documents.file_format') }}</span>
            </template>
            <template slot-scope="scope">
              <img v-if="scope.row.doc === 'pdf'" src="@/assets/icons/icon-pdf-document.svg">
              <img v-if="scope.row.doc === 'docx' || scope.row.doc === 'doc'"
                src="@/assets/icons/icon-docx-document.svg">
              <img v-if="scope.row.doc === 'xlsx' || scope.row.doc === 'xls'"
                src="@/assets/icons/icon-xlsx-document.svg">
            </template>
          </el-table-column>
          <el-table-column width="350">
            <template slot="header">
              <div class="table-header-line">
                <div class="holdings__table-th-sort">
                  <button class="holdings__table-th-arrow up"
                    :class="{ active: sort_by === 'stored_at' && sort_dir === 'asc' }"
                    @click="changeSortParams('asc', 'stored_at')">
                    <img src="../assets/icons/icon-arrow.svg" alt="">
                  </button>
                  <button class="holdings__table-th-arrow"
                    :class="{ active: sort_by === 'stored_at' && sort_dir === 'desc' }"
                    @click="changeSortParams('desc', 'stored_at')">
                    <img src="../assets/icons/icon-arrow.svg" alt="">
                  </button>
                </div>
                <div v-if="lang === 'ru'" :key="lang" :class="{'locale-usa': locale_glob==='USA'}">
                  <date-range-picker class="documents__period-calendar" ref="picker-period" v-model="date"
                    single-date-picker="range" :auto-apply="true" :always-show-calendars="true" :ranges="false"
                    :locale-data="localeRu" :append-to-body="true" @toggle="pickerPosition"
                    @update="changeFilter('date')" opens="left">
                    <template #input="picker">
                      <span class="name-label">{{ $t('documents.publication_date') }}</span> {{
                        fixDate(picker.startDate,
                        picker.endDate)
                      }}
                    </template>
                  </date-range-picker>
                </div>
                <div v-if="lang === 'en'" :key="lang">
                  <date-range-picker class="documents__period-calendar" ref="picker-period" v-model="date"
                    single-date-picker="range" :auto-apply="true" :always-show-calendars="true" :ranges="false"
                    :locale-data="localeEn" :append-to-body="true" @toggle="pickerPosition"
                    @update="changeFilter('date')" opens="left">
                    <template #input="picker">
                      <span class="name-label">{{ $t('documents.publication_date') }}</span> {{
                        fixDate(picker.startDate,
                        picker.endDate)
                      }}
                    </template>
                  </date-range-picker>
                </div>
                <!-- <date-range-picker class="documents__period-calendar" ref="picker-period" v-model="date"
                  single-date-picker="range" :auto-apply="true" :always-show-calendars="true" :ranges="false"
                  :locale-data="locale" :append-to-body="true" @toggle="pickerPosition" @update="changeFilter('date')"
                  opens="left">
                  <template #input="picker">
                    <span class="name-label">{{ $t('documents.publication_date') }}</span> {{
                      fixDate(picker.startDate,
                      picker.endDate)
                    }}
                  </template>
                </date-range-picker> -->
              </div>
            </template>
            <template slot-scope="scope">
              {{ fixDateFormat(scope.row.stored_at) }}
            </template>
          </el-table-column>

          <el-table-column width="180">
            <template slot-scope="scope">
              <div class="documents__row-actions">
                <ButtonElement :text="$t('button_props.download')" @click-button="downloadDoc(scope.row.id, scope.row.file_name)" />
                <el-popconfirm 
                  v-if="role === 'manager' || role === 'finance'"
                  :title="$t('documents.confirm_delete')"
                  :confirm-button-text="$t('documents.delete')"
                  :cancel-button-text="$t('documents.cancel')"
                  :hideIcon="true"
                  @confirm="deleteDoc(scope.row.id)"
                >
                  <template #reference>
                    <img src="@/assets/icons/icon-delete-4.svg" class="documents__row-actions-dell">
                  </template>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
          <div class="el-table__empty-block" slot="append" v-if="!documentsList||documentsList.length=='0'">
            <p class="el-table__empty-text ">{{locale_glob==='RU'?'Нет данных':'No data' }}</p>
          </div>
        </el-table>
        <el-table
          v-if="locale_glob==='USA'"
          :data="documentsList"
          style="width: 100%"
        >
          <el-table-column prop="number" :label="$t('documents.file_number')" />
          <el-table-column prop="type">
            <template slot="header">
              <span class="name-label">{{ $t('documents.file_type') }}</span>
            </template>
            <template slot-scope="scope">
              <span :style="`color: ${getColor(scope.row.file_type)}`">
                {{  getFileTypeUSA(scope.row.file_type) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="amount">
            <template slot="header">
              <span class="name-label">{{ $t('documents.invoice_amount') }}</span>
            </template>
            <template slot-scope="scope">
              <span :style="`color: ${getColor(scope.row.file_type)}`">
                {{  scope.row.invoice_amount }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="currency">
            <template slot="header">
              <span class="name-label">{{ $t('documents.invoice_currency') }}</span>
            </template>
            <template slot-scope="scope">
              <span :style="`color: ${getColor(scope.row.file_type)}`">
                {{  scope.row.invoice_currency }}
              </span>
            </template>
          </el-table-column>
          
          <el-table-column prop="doc">
            <template slot="header">
              <span class="name-label">{{ $t('documents.file_format') }}</span>
            </template>
            <template slot-scope="scope">
              <img v-if="scope.row.doc === 'pdf'" src="@/assets/icons/icon-pdf-document.svg">
              <img v-if="scope.row.doc === 'docx' || scope.row.doc === 'doc'"
                src="@/assets/icons/icon-docx-document.svg">
              <img v-if="scope.row.doc === 'xlsx' || scope.row.doc === 'xls'"
                src="@/assets/icons/icon-xlsx-document.svg">
            </template>
          </el-table-column>
          <el-table-column >
            <template slot="header">
              <div class="table-header-line">
                <div class="holdings__table-th-sort">
                  <button class="holdings__table-th-arrow up"
                    :class="{ active: sort_by === 'stored_at' && sort_dir === 'asc' }"
                    @click="changeSortParams('asc', 'stored_at')">
                    <img src="../assets/icons/icon-arrow.svg" alt="">
                  </button>
                  <button class="holdings__table-th-arrow"
                    :class="{ active: sort_by === 'stored_at' && sort_dir === 'desc' }"
                    @click="changeSortParams('desc', 'stored_at')">
                    <img src="../assets/icons/icon-arrow.svg" alt="">
                  </button>
                </div>
                <div v-if="lang === 'ru'" :key="lang" :class="{'locale-usa': locale_glob==='USA'}">
                  <date-range-picker class="documents__period-calendar" ref="picker-period" v-model="date"
                    single-date-picker="range" :auto-apply="true" :always-show-calendars="true" :ranges="false"
                    :locale-data="localeRu" :append-to-body="true" @toggle="pickerPosition"
                    @update="changeFilter('date')" opens="left">
                    <template #input="picker">
                      <span class="name-label">{{ $t('documents.publication_date') }}</span> {{
                        fixDate(picker.startDate,
                        picker.endDate)
                      }}
                    </template>
                  </date-range-picker>
                </div>
                <div v-if="lang === 'en'" :key="lang">
                  <date-range-picker class="documents__period-calendar" ref="picker-period" v-model="date"
                    single-date-picker="range" :auto-apply="true" :always-show-calendars="true" :ranges="false"
                    :locale-data="localeEn" :append-to-body="true" @toggle="pickerPosition"
                    @update="changeFilter('date')" opens="left">
                    <template #input="picker">
                      <span class="name-label">{{ $t('documents.publication_date') }}</span> {{
                        fixDate(picker.startDate,
                        picker.endDate)
                      }}
                    </template>
                  </date-range-picker>
                </div>
                <!-- <date-range-picker class="documents__period-calendar" ref="picker-period" v-model="date"
                  single-date-picker="range" :auto-apply="true" :always-show-calendars="true" :ranges="false"
                  :locale-data="locale" :append-to-body="true" @toggle="pickerPosition" @update="changeFilter('date')"
                  opens="left">
                  <template #input="picker">
                    <span class="name-label">{{ $t('documents.publication_date') }}</span> {{
                      fixDate(picker.startDate,
                      picker.endDate)
                    }}
                  </template>
                </date-range-picker> -->
              </div>
            </template>
            <template slot-scope="scope">
              {{ fixDateFormat(scope.row.stored_at) }}
            </template>
          </el-table-column>
          <el-table-column prop="status">
            <template slot="header">
              <span class="name-label">{{ $t('documents.status') }}</span>
            </template>
            <template slot-scope="scope">
              <span :style="`color: ${getColor(scope.row.file_type)}`">
                {{  scope.row.status}}
              </span>
            </template>
          </el-table-column>
          <el-table-column >
            <template slot-scope="scope">
              <div class="documents__row-actions">
                <ButtonElement :text="$t('button_props.download')" @click-button="downloadDoc(scope.row.id, scope.row.file_name)" />
                <el-popconfirm 
                  v-if="role === 'manager' || role === 'finance'"
                  :title="$t('documents.confirm_delete')"
                  :confirm-button-text="$t('documents.delete')"
                  :cancel-button-text="$t('documents.cancel')"
                  :hideIcon="true"
                  popper-class="locale-usa"
                  @confirm="deleteDoc(scope.row.id)"
                >
                  <template #reference>
                    <img src="@/assets/icons/icon-delete-4.svg" class="documents__row-actions-dell">
                  </template>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
          <div class="el-table__empty-block" slot="append" v-if="!documentsList||documentsList.length=='0'">
            <p class="el-table__empty-text ">{{locale_glob==='RU'?'Нет данных':'No data' }}</p>
          </div>
        </el-table>
        <div class="holdings__pagination">
          <el-pagination v-if="documentsPagination && documentsPagination.total >= 10" layout="prev, pager, next"
            :total="documentsPagination.total" :page-size="10" :current-page="documentsPagination.currentPage"
            @current-change="getNewPage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fixDateFormat from '@/mixin/fixDate'
import DateRangePicker from 'vue2-daterange-picker'
import ButtonElement from '@/components/elements/ButtonElement'

export default {
  name: 'Documents',
  components: {
    DateRangePicker,
    ButtonElement,
  },
  mixins: [fixDateFormat],
  data() {
    return {
      locale_glob: process.env.VUE_APP_LOCALE,
      lang: this.$i18n._vm.locale,
      sort_dir: null,
      sort_by: null,
      date: {
        startDate: null,
        endDate: null
      },
      localeRu: {
        direction: 'ltr',
        format: 'mm/dd/yyyy',
        separator: ' - ',
        daysOfWeek: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
        monthNames: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'нояб', 'дек'],
        firstDay: 1
      },
      localeEn: {
        direction: 'ltr',
        format: 'mm/dd/yyyy',
        separator: ' - ',
        daysOfWeek: ['sun', 'mon', 'tu', 'wen', 'th', 'fr', 'sat'],
        monthNames: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
        firstDay: 1
      },
      monthsRu: [
        {
          id: 0,
          shortName: 'янв'
        },
        {
          id: 1,
          shortName: 'фев'
        },
        {
          id: 2,
          shortName: 'мар'
        },
        {
          id: 3,
          shortName: 'апр'
        },
        {
          id: 4,
          shortName: 'май'
        },
        {
          id: 5,
          shortName: 'июн'
        },
        {
          id: 6,
          shortName: 'июл'
        },
        {
          id: 7,
          shortName: 'авг'
        },
        {
          id: 8,
          shortName: 'сен'
        },
        {
          id: 9,
          shortName: 'окт'
        },
        {
          id: 10,
          shortName: 'нояб'
        },
        {
          id: 11,
          shortName: 'дек'
        }
      ],
      monthsEn: [
        {
          id: 0,
          shortName: 'jan'
        },
        {
          id: 1,
          shortName: 'feb'
        },
        {
          id: 2,
          shortName: 'mar'
        },
        {
          id: 3,
          shortName: 'apr'
        },
        {
          id: 4,
          shortName: 'may'
        },
        {
          id: 5,
          shortName: 'jun'
        },
        {
          id: 6,
          shortName: 'jul'
        },
        {
          id: 7,
          shortName: 'aug'
        },
        {
          id: 8,
          shortName: 'sep'
        },
        {
          id: 9,
          shortName: 'oct'
        },
        {
          id: 10,
          shortName: 'nov'
        },
        {
          id: 11,
          shortName: 'dec'
        }
      ],
      activePeriod: 'day',
    }
  },
  computed: {
    role() {
      return this.$store.state.Auth.role
    },
    userId() {
      return this.$store.state.Auth.user.id
    },
    documentsList() {
      return this.$store.state.Documents.documentsList
    },
    documentsPagination() {
      return this.$store.state.Documents.documentsPagination
    },
    businessAccountId() {
      return this.$store.state.Auth.activeAccount.id
    },
    getLang() {
      let language = this.$i18n._vm.locale
      return language
    },
    clientRole() {
        return this.$store.state.Auth.user.role
    },
    isUpdateTableUpdated() {
      return this.$store.state.Documents.updateTable;
    }
  },
  watch: {
    $route() {
      this.getDocuments(this.$route.query, this.businessAccountId)
    },
    businessAccountId() {
      this.getDocuments(this.$route.query, this.businessAccountId)
    },
    getLang: {
      deep: true,
      handler(val) {
        this.dataPickerforceRerender(val)
      }
    },
    isUpdateTableUpdated() {
      this.getDocuments(this.$route.query, this.businessAccountId)
    }
  },
  created() {
    this.getDocuments(this.$route.query, this.businessAccountId)
  },
  methods: {
    dataPickerforceRerender(value) {
      this.lang = value
    },
    changeFilter(filterType) {
      const query = { ...this.$route.query }
      if (filterType === 'date') {
        query.start_date = this.fixDateFormatTransactions(this.date.startDate),
          query.end_date = this.fixDateFormatTransactions(this.date.endDate, true)
      }
      this.$router.push({ query })
    },
    changeSortParams(direction, type) {
      this.sort_dir = direction
      this.sort_by = type
      const query = { ...this.$route.query, sort_dir: direction, sort_by: type }
      this.$router.push({ query })
    },
    getDocuments(params, id) {
      const data = JSON.parse(JSON.stringify(params))
      data.business_account_id = id
      data.sort_by = 'number'
      data.sort_dir='desc'

      this.$store.dispatch('Documents/getDocuments', data)
    },
    getNewPage(page) {
      const query = { ...this.$route.query, page }
      this.$router.push({ query })
    },
    getColor(type) {
       // return type === 'act' ? '#FF7F50' : '#27AE60';
      return type === 'act' ? (this.locale_glob === 'USA' ? '#FF7F50' : '#395FE4') : '#27AE60';
    },
    getFileType(type) {
      if (type === 'act') {
        return 'Акт'
      } else if (type === 'invoice') {
        return 'Счет'
      }
      return type
    },
    getFileTypeUSA(type) {
      if (type === 'act') {
        return 'Monthly report'
      } else if (type === 'invoice') {
        return 'Invoice'
      } else if (type ==='terms') {
        return 'Special Terms'
      }else if (type ==='agreement') {
        return 'Agreement'
      }
      return type
    },
    pickerPosition() {
      const body = document.querySelector('body')
      body.classList.toggle("picker-open")
    },
    fixDate(start, end) {
      if (start && end) {
        let str = ''
        const startDay = start.getDate()
        const endDay = end.getDate()

        const startMonth = start.getMonth()
        const endMonth = end.getMonth()
        let nameStartMonth, nameEndMonth
        if (this.$i18n._vm.locale === 'ru') {
          nameStartMonth = this.monthsRu.find(el => el.id === startMonth).shortName
          nameEndMonth = this.monthsRu.find(el => el.id === endMonth).shortName
        } else if (this.$i18n._vm.locale === 'en') {
          nameStartMonth = this.monthsEn.find(el => el.id === startMonth).shortName
          nameEndMonth = this.monthsEn.find(el => el.id === endMonth).shortName
        }
        // const nameStartMonth = this.months.find(el => el.id === startMonth).shortName
        // const nameEndMonth = this.months.find(el => el.id === endMonth).shortName

        if (startDay === endDay && startMonth === endMonth) {
          str = startDay + ' ' + nameStartMonth
        } else if (startMonth === endMonth) {
          str = startDay + '-' + endDay + ' ' + nameStartMonth
        } else {
          str = startDay + ' ' + nameStartMonth + ' - ' + endDay + ' ' + nameEndMonth
        }

        return str
      }
      
    },
    downloadDoc(id, name) {
      this.$store.dispatch('Documents/getDocumentFile', id)
        .then((response) => {
          const link = document.createElement('a');
          link.href = response.data.data;
          link.download = name;
          document.body.appendChild(link);
          link.click();
        })
    },
    deleteDoc(id) {
      this.$store.dispatch('Documents/dellDocumentFile', {id, params: {additional_manager: this.userId}})
        .then(() => {
          this.getDocuments(this.$route.query, this.businessAccountId)
        })
    },
    addDocument() {
        if(this.clientRole ==='manager' || this.clientRole === 'finance' || this.clientRole === 'admin') {
          this.$store.commit('Dialog/setData', { label: 'documentTrigger', data: 'ActiveDocument' })
					this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddDocument' })
					this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
        }
    },
    updateTable() {
      console.log('Table updated')
      this.getDocuments(this.$route.query, this.businessAccountId)
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";
.el-popover.locale-usa {
  .el-popconfirm {
    .el-button--primary {
      background-color: #FF7F50;
      border-color: #FF7F50;
    }
  }
}

.el-table__empty-block {
  display: none;
}
.el-table__append-wrapper {
  .el-table__empty-block {
    display: flex;
  }
}


.picker-open {
  .documents__period-calendar {
    .reportrange-text {
      &::after {
        transform: translate3d(0, -50%, 0) rotate(180deg);
      }
    }

    .daterangepicker.opensleft.opensleft {
      right: -10px;
    }
  }
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: $primary;
}

.documents {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.6rem;
  }

  &__title {
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.6rem;
    &.locale-usa {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 700;
      font-size: 3.2rem;
    }
  }

  &__add-new {
    cursor: pointer;
    color: var(--primary);
  }

  &__table {
    &.locale-usa {
      padding: 1.6rem 2.4rem 0 2.4rem;
      background-color: #FFFFFF;
    }
    &-dropdown {
      width: 100%;

      &-btn {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;

        &.left {
          justify-content: flex-start;
          align-items: center;
        }

        .el-icon-arrow-down {
          margin-left: .5rem;
        }
      }

      &-list {
        background: #FFFFFF;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
        border: none;
        border-radius: 3px;
        padding: 0;
        width: 14.6rem;

        .popper__arrow {
          display: none;
        }

        &-item {
          font-size: 1.6rem;
          line-height: 1.9;
          color: $basicBlack;
          transition: color .4s linear, background-color .4s linear;
          border-bottom: 1px solid $gray5;
          padding: 15px;

          &:hover {
            color: $primary !important;
            background-color: $gray9 !important;
          }

          &:last-of-type {
            border-bottom: none;
          }

          .input__item-checkbox {
            cursor: pointer;
          }

          .input__item-checkbox__title {
            font-size: 1.6rem;
          }
        }
      }
    }

    .el-table th>.cell,
    .el-table td>.cell {
      padding: 0 10px;
    }
  }

  &__row-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0 2rem;

    &-dell {
      cursor: pointer;
    }
  }

  &__period-calendar {
    .reportrange-text {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.9rem;
      color: $basicBlack;
      background: transparent;
      border: none !important;
      position: relative;
      padding: 0 15px 0 0 !important;

      &::after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background: url("../assets/icons/icon-arrow.svg") no-repeat;
        background-size: contain;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        transition: 0.3s ease-out;
      }
    }
  }

  .calendar-table td,
  .calendar-table th {
    padding: 2px;
    text-align: center !important;
  }

  .calendar-table th {
    font-weight: 600;
    color: #000;
  }

  .name-label {
    font-size: 1.6rem;
    color: $gray2;
  }

  .container {
    @include below(600px) {
      padding: 0;
    }
  }

  .table-header-line {
    display: flex;
    align-items: center;

    .holdings__table-th-sort {
      margin-right: 1rem;
    }
  }
}

.popup-item {
  font-size: 1.6rem;
  cursor: pointer;
}

@include below(600px) {
  .picker-open .daterangepicker.opensleft {
    right: 20px !important;
  }
}
</style>
