import axios from 'axios'

const apiUrl = process.env.VUE_APP_API_URL

const state = () => ({
  ordersList: null,
  depositAmount: [],
  chooseCampaign: null,
  paymentOptions: null,
  filledAccount: null,
  account: null,
})
const getters = {
  selectedCampaigns: (state) => {
    let arr = []
    if (state.chooseCampaign && state.depositAmount.length) {
      arr = state.depositAmount.filter(el => state.chooseCampaign.find(el2 => el2 === el.campaign_id))
    }
    return arr
  },
}
const mutations = {
  setData (state, data) {
    state[data.label] = data.data
  },
  setDepositAmount(state, data) {
    if (state.depositAmount.length) {
      const elem = state.depositAmount.find(el => el.campaign_id === data.campaign_id)
      if (elem) elem.amount = data.amount
      else state.depositAmount.push(data)
    } else {
      state.depositAmount.push(data)
    }
  },
}
const actions = {
  getPaymentOptions ({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/payment/gateway`, method: 'GET', params })
        .then(resp => {
          commit('setData', { label: 'paymentOptions', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getOrdersToAddBalance ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/business-account/${id}/order`, method: 'GET' })
        .then(resp => {
          commit('setData', { label: 'ordersList', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  addOrder ( ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/business-account/${data.id}/order`, method: 'POST', data: data.data })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  addManualPayment ( ctx, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/admin/add-manual-deposit`,
        method: 'POST',
        data: data.data,
        headers: { 'Authorization': `Bearer ${ data.token }` }
      })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  addCashlessPayment ( ctx, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/tracker/cashless-payment-request`,
        method: 'POST',
        data: data
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getLinkForOrder (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/business-account/${data.businessId}/order/${data.id}/payment/link`, method: 'GET' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  transferToCampaigns (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/advertservice/change-account-balance`, method: 'POST', data })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err.response)
        })
    })
  },
  changeCashback(ctx, { id, data, token }) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/admin/change-cashback-condition/${id}`,
        method: 'POST',
        data,
        headers: { 'Authorization': `Bearer ${ token }` }
      })
        .then(resp => {
     
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err.response)
        })
    })
  },
  changeCommission(ctx, { id, data, token }) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/business-account/${id}/commissions-conditions`,
        method: 'PATCH',
        data,
        headers: { 'Authorization': `Bearer ${ token }` }
      })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err.response)
        })
    })
  },
  setFilledAccount({commit}, data) {
    commit('setData', { label: 'filledAccount', data })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
