import Vue from 'vue'
import Vuex from 'vuex'

import Auth from './modules/auth'
import Ad from './modules/ad'
import Marketplace from './modules/marketplace'
import Support from './modules/support'
import Dialog from './modules/dialog'
import Balance from './modules/balance'
import Users from './modules/users'
import Company from './modules/company'
import Documents from './modules/documents'
import Reports from './modules/reports'
import lang from './modules/lang'
import currency from './modules/currency'
import promo from './modules/promo'
import Clients from './modules/clients'
import Contracts from './modules/contracts'
import InitialContracts from './modules/initialContracts'
import Creatives from './modules/creatives'
import faq from './modules/faq'
import SetCommissions from './modules/setCommissions'
import Representatives from './modules/representatives'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters : {},
  mutations: {},
  actions: {},
  modules: {
    Auth,
    Ad,
    Marketplace,
    Support,
    Dialog,
    Balance,
    Users,
    Company,
    Documents,
    Reports,
    lang,
    currency,
    promo,
    Clients,
    Contracts,
    Creatives,
    InitialContracts,
    faq,
    SetCommissions,
    Representatives
  }
})
