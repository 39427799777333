<template>
  <div class="creatives-wrapper page" v-loading="preparingQueriesLoading">
    <div class="creatives-wrapper__breadcrumbs">
      <div 
        v-for="breadcrumb in getBreadcrumbs" 
        :key="breadcrumb.value"
        class="creatives-wrapper__breadcrumb"
      >
        <div class="creatives-wrapper__breadcrumb-title">{{ breadcrumb.name }}</div>
        <div class="creatives-wrapper__breadcrumb-content">
          <span>{{ breadcrumb.value }}</span>
          <img
            src="@/assets/icons/icon-close.svg"
            class="creatives-wrapper__breadcrumb-close-icon"
            @click="clearBreadcrumb(breadcrumb)"
          >
        </div>
      </div>
    </div>
    <div class="creatives-wrapper__title">
      <Title text="Креативы" />
    </div>
    <div class="creatives-wrapper__buttons">
      <div class="creatives-wrapper__tabs">
        <div 
          class="creatives-wrapper__tab" 
          :class="{'creatives-wrapper__tab--active': getActiveTabName === Tabs.CLIENTS}"
          @click="changeActiveTab(Tabs.CLIENTS)"
        >
          Заказчики
          <Tooltip 
            class="creatives-wrapper__tooltip" 
            data="Введите ваши данные в форме создания заказчика. Если заказчик уже создан, выберите его в таблице. Выбрать можно только заказчика со статусом “Активный” или “Регистрация”."
          />
        </div>
        <div 
          class="creatives-wrapper__tab" 
          :class="{'creatives-wrapper__tab--active': getActiveTabName === Tabs.FINAL_CONTRACTS}"
          @click="changeActiveTab(Tabs.FINAL_CONTRACTS)"
        >
          Доходные договоры
          <Tooltip 
            class="creatives-wrapper__tooltip" 
            data="Введите данные вашего договора с ProfitAds. Если договор уже создан, выберите из списка договор, на основании которого будет запускаться реклама."
          />
        </div>
        <div 
          class="creatives-wrapper__tab" 
          :class="{'creatives-wrapper__tab--active': getActiveTabName === Tabs.INITIAL_CONTRACTS}"
          @click="changeActiveTab(Tabs.INITIAL_CONTRACTS)"
        >
          Изначальные договоры
          <Tooltip 
            class="creatives-wrapper__tooltip" 
            data="Если Вы планируете запускать рекламу для третьих лиц, создайте изначальный договор. Введите данные вашего договора с конечным рекламодателем. Если договор уже создан, выберите из списка договор, на основании которого будет запускаться реклама."
          />
        </div>
        <div 
          class="creatives-wrapper__tab" 
          :class="{'creatives-wrapper__tab--active': getActiveTabName === Tabs.CREATIVES}"
          @click="changeActiveTab(Tabs.CREATIVES)"
        >
          Креативы
        </div>
      </div>
      <ButtonElement
        v-if="getActiveTabName === Tabs.CLIENTS && clients.length"
        class="creatives-wrapper__add-customer-btn"
        text="Добавить заказчика"
        view="empty"
        @click-button="openDialogAddingCustomer"
      />
      <ButtonElement
        v-if="getActiveTabName === Tabs.FINAL_CONTRACTS && getClientId"
        class="creatives-wrapper__add-customer-btn"
        text="Добавить договор"
        view="empty"
        @click-button="openDialogAddingFinalContract"
      />
      <ButtonElement
        v-if="getActiveTabName === Tabs.CREATIVES && getFinalContractId"
        class="creatives-wrapper__add-customer-btn"
        text="Загрузить креатив"
        view="empty"
        @click-button="openDialogAddingCreative"
      />
      <ButtonElement
        v-if="getActiveTabName === Tabs.INITIAL_CONTRACTS && getFinalContractId && getClientId"
        class="creatives-wrapper__add-customer-btn"
        text="Добавить договор"
        view="empty"
        @click-button="openDialogAddingInitialContract"
      />
    </div>
    <div class="creatives-wrapper__content">
      <Clients v-if="getActiveTabName === Tabs.CLIENTS" />
      <Contracts v-if="getActiveTabName === Tabs.FINAL_CONTRACTS" />
      <InitialContracts v-if="getActiveTabName === Tabs.INITIAL_CONTRACTS" />
      <Creatives v-if="getActiveTabName === Tabs.CREATIVES" />
    </div>
  </div>
</template>

<script>
import Title from "@/components/elements/Title";
import ButtonElement from "@/components/elements/ButtonElement";
import Clients from "@/components/blocks/Clients"
import Contracts from "@/components/blocks/Contracts"
import InitialContracts from "@/components/blocks/InitialContracts"
import Creatives from "@/components/blocks/Creatives"
import { Tabs } from "@/utils/clients"
import { clearQuery, changeQuery } from "@/utils/helpers"
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import fixDateFormat from '@/mixin/fixDate'
import Tooltip from '../components/elements/Tooltip.vue';

const BreadcrumbKeys = {
  CLIENT: 'CLIENT',
  FINAL_CONTRACT: 'FINAL_CONTRACT',
  INITIAL_CONTRACT: 'INITIAL_CONTRACT',
}

const BreadcrumbLabels = {
  [BreadcrumbKeys.CLIENT]: 'Заказчик:',
  [BreadcrumbKeys.FINAL_CONTRACT]: 'Доходный договор №:',
  [BreadcrumbKeys.INITIAL_CONTRACT]: 'Изначальный договор №:',
}

export default {
  name: 'CreativesWrapper',
  mixins: [fixDateFormat],
  components: {
    InitialContracts,
    Title,
    ButtonElement,
    Clients,
    Contracts,
    Creatives,
    Tooltip,
  },
  data () {
    return {
      BreadcrumbLabels,
      Tabs,
      preparingQueriesLoading: true,
      mountedParams: null
    }
  },
  computed: {
    ...mapState('Clients', ['clients', 'allClients']),
    ...mapState('Contracts', {
      finalContracts: 'finalContracts',
      finalContractsTableFilters: 'filters',
      allFinalContracts: 'allFinalContracts',
    }),
    ...mapState('InitialContracts', {
      initialContracts: 'initialContracts',
      initialContractsTableFilters: 'filters',
      allInitialContracts: 'allInitialContracts'
    }),
    ...mapState('Creatives', {
      creatives: 'creatives',
      creativesTableFilters: 'filters'
    }),
    ...mapGetters('Clients', ['getClientById']),
    ...mapGetters('Contracts', ['getFinalContractById']),
    ...mapGetters('InitialContracts', ['getInitialContractById']),
    getActiveTabName() {
      return this.$route.query.view
    },
    getInitialContractId() {
      return this.$route.query.initialContractId
    },
    getFinalContractId() {
      return this.$route.query.finalContractId
    },
    getClientId() {
      return this.$route.query.clientId
    },
    getBreadcrumbs() {
      const breadcrumbs = []

      if (this.getClientId && this.allClients.length) {
        const client = this.getClientById(this.getClientId)
        
        if (client) {
          breadcrumbs.push({
            name: BreadcrumbLabels[BreadcrumbKeys.CLIENT],
            value: client.Name
          })
        }
      }

      if (this.getFinalContractId && this.allFinalContracts.length) {
        breadcrumbs.push({
          name: BreadcrumbLabels[BreadcrumbKeys.FINAL_CONTRACT],
          value: this.getFinalContractById(this.getFinalContractId).Number
        })
      }

      if (this.getInitialContractId && this.allInitialContracts.length) {
        breadcrumbs.push({
          name: BreadcrumbLabels[BreadcrumbKeys.INITIAL_CONTRACT],
          value: this.getInitialContractById(this.getInitialContractId).Number
        })
      }

      return breadcrumbs
    },
  },
  watch: {
    $route(to) {
      localStorage.setItem('CreativeSavedQueries', JSON.stringify(to.query))
    },
    '$route.query.view': {
      handler(value) {
        this.changeActiveTab(value)
      },
      immediate: true
    },
  },
  methods: {
    ...mapActions('Clients', ['fetchAllClients']),
    ...mapActions('Contracts', ['fetchAllFinalContracts']),
    ...mapActions('InitialContracts', ['fetchAllInitialContracts']),
    ...mapMutations('Contracts', {
      setPaginationFinalContractsTable: 'setPagination',
    }),
    ...mapMutations('InitialContracts', {
      setPaginationInitialContractsTable: 'setPagination',
    }),
    ...mapMutations('Creatives', {
      setPaginationCreativesTable: 'setPagination',
    }),
    async clearBreadcrumb(breadcrumb) {
      if (breadcrumb.name === BreadcrumbLabels[BreadcrumbKeys.CLIENT]) {
        clearQuery('clientId', this.$route.query, this.$router)
        changeQuery('view', Tabs.CLIENTS, this.$route.query, this.$router)

        if (this.getActiveTabName === Tabs.FINAL_CONTRACTS) {
          await this.$store.dispatch('Contracts/getFinalContracts', {
            ...this.finalContractsTableFilters,
            ...(this.getClientId && { ClientId: this.getClientId })
          }).then((finalContracts) => {
            this.setPaginationFinalContractsTable({
              pageSize: 20,
              total: finalContracts.length,
              currentPage: 1,
            })
          })
        }
      }

      if (breadcrumb.name === BreadcrumbLabels[BreadcrumbKeys.FINAL_CONTRACT]) {
        clearQuery('finalContractId', this.$route.query, this.$router)
        changeQuery('view', Tabs.FINAL_CONTRACTS, this.$route.query, this.$router)
      }
      if (breadcrumb.name === BreadcrumbLabels[BreadcrumbKeys.INITIAL_CONTRACT]) {
        clearQuery('initialContractId', this.$route.query, this.$router)
        changeQuery('view', Tabs.INITIAL_CONTRACTS, this.$route.query, this.$router)
      }

      if (breadcrumb.name === BreadcrumbLabels[BreadcrumbKeys.FINAL_CONTRACT] || breadcrumb.name === BreadcrumbLabels[BreadcrumbKeys.CLIENT]) {
        if (this.getActiveTabName === Tabs.INITIAL_CONTRACTS) {
          await this.$store.dispatch('InitialContracts/getInitialContracts', {
            ...this.initialContractsTableFilters,
            ...(this.getClientId && { ContractorId: this.getClientId }),
            ...(this.getFinalContractId && { FinalContractId: this.getFinalContractId }),
          }).then((initialContracts) => {
            this.setPaginationInitialContractsTable({
              pageSize: 20,
              total: initialContracts.length,
              currentPage: 1,
            })
          })
        }
      }

      if (
        breadcrumb.name === BreadcrumbLabels[BreadcrumbKeys.FINAL_CONTRACT] || 
        breadcrumb.name === BreadcrumbLabels[BreadcrumbKeys.CLIENT] || 
        breadcrumb.name === BreadcrumbLabels[BreadcrumbKeys.INITIAL_CONTRACT]
      ) {
        if (this.getActiveTabName === Tabs.CREATIVES) {
          await this.$store.dispatch('Creatives/getCreatives', {
            ...this.creativesTableFilters,
            ...(this.creativesTableFilters.CreativeGroupStartDate && { CreativeGroupStartDate: this.fixDateFormatNumbers(this.filters.CreativeGroupStartDate) }),
            ...(this.creativesTableFilters.CreativeGroupEndDate && { CreativeGroupEndDate: this.fixDateFormatNumbers(this.filters.CreativeGroupEndDate) }),
            ...(this.getFinalContractId && { FinalContractId: this.getFinalContractId }),
            ...(this.getInitialContractId && { InitialContractId: this.getInitialContractId }),
            ...(this.getClientId && { ContractorId: this.getClientId }),
          }).then((initialContracts) => {
            this.setPaginationCreativesTable({
              pageSize: 20,
              total: initialContracts.length,
              currentPage: 1,
            })
          })
        }
      }
    },
    openDialogAddingInitialContract() {
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddInitialContract' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    openDialogAddingCreative() {
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddCreative' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    openDialogAddingCustomer() {
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddClient' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    openDialogAddingFinalContract() {
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddFinalContract' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    changeActiveTab(tabName) {
      this.$router.push({ 
        path: 'creatives', query: {
          ...this.$route.query, 
          view: tabName 
        }
      }).catch(() => {})
    },
    async prepareData() {
      await this.$store.dispatch('Creatives/getFiasObjects')
      await this.fetchAllClients()
      await this.fetchAllInitialContracts()
      await this.fetchAllFinalContracts()
    },
    openBindingCreativeDialog() {
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'BindCreative' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
  },
  async created() {
    const prevQueries = JSON.parse(localStorage.getItem('CreativeSavedQueries'))
    this.mountedParams = this.$router.currentRoute.query
    this.$router.push({ query: {
      view: prevQueries && prevQueries.view || Tabs.CLIENTS,
      ...(prevQueries && prevQueries.clientId && { clientId: prevQueries.clientId}),
      ...(prevQueries && prevQueries.finalContractId && { finalContractId: prevQueries.finalContractId}),
      ...(prevQueries && prevQueries.finalContractId && { finalContractId: prevQueries.finalContractId}),
      ...(prevQueries && prevQueries.initialContractId && { initialContractId: prevQueries.initialContractId}),
    }}).catch(() => {}).finally(() => {
      this.preparingQueriesLoading = false
    })

    await this.prepareData()
  },
  mounted() {
    if((this.mountedParams.platform && this.mountedParams.platform === 'telegram')) {
      const queryParams = {...this.$route.query}
      if(queryParams.clientId && queryParams.finalContractId) {
       this.openBindingCreativeDialog()
      }
    }
  },
  beforeDestroy() {
    this.mountedParams = null
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";
.creatives-wrapper {
  &__tooltip {
    position: static;
  }
  &__breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3.5rem;
    gap: 1rem;

    & > *:not(:last-child) {
      margin-right: 4rem;
    }
  }

  &__breadcrumb {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    &-title {
      color: $gray2;
      font-size: 1.4rem;
      line-height: 1.4rem;
      color: #333333;
    }

    &-content {
      position: relative;
      background: $gray8;
      padding: .5rem 1rem;
      font-size: 1.4rem;
      line-height: 1.4rem;
    }

    &-close-icon {
      position: absolute;
      border-radius: .3rem;
      top: -1.3rem;
      right: -1.3rem;
      width: 1.5rem;
      height: 1.5rem;
      padding: .1rem;
      background: $gray8;
      cursor: pointer;
    }
  }

  &__add-customer-btn {
    width: 24.3rem;
    height: 4.8rem;
  }
   
  &__title {
    margin-bottom: 3.2rem;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.2rem;

    @include below(576px) {
      flex-direction: column-reverse;
      gap: 2rem;
    }
  }
  
  &__new-application {
    height: 50px !important;
    padding: 0 5.1rem;
  }

  &__tabs {
    display: flex;
    flex-wrap: wrap;
    
    @include below(576px) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  &__tab {
    padding: 0 40px;
    height: 50px;
    display: flex;
    align-items: center;
    color: $gray2;
    font-weight: 700;
    cursor: pointer;
    position: relative;

    &--active {
      box-shadow: 0px 5px 10px #9e9e9e1a;
      border-radius: 7.64082px;
      background: $primaryLight;
      color: $basicBlack;
    }
  }
}
</style>