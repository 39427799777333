<template>
  <div v-loading="clientsIsLoading" class="clients-table">
    <el-table 
      v-if="!clientsIsLoading && getClients.length || clientsIsLoading && hasActiveFilter || !clientsIsLoading && hasActiveFilter"
      :data="getClients"
      style="min-width: 700px;"
      class="clients-table__table"
      :key="tableComponentKey"
      @current-change="toDetail"
    >
      <el-table-column>
        <template slot="header">
          <TableHeaderFilterSelector class="clients-table__table-header-cell-filter" :isOpen="!!filters.Status">
            <template #content>
              <div>Статус</div>
            </template>
            <template #options>
              <FloatSelector 
                class="clients-table__table-filter"
                required
                :options="Object.keys(Status).map((key) => ({id: key, name: Status[key]}))"
                :select-fitrst-item="false"
                :default-select-item-id="filters.Status"
                :popover-width="220"
                @handleChange="(val) => setFilter('Status', val)"
              >
                <template #icons>
                  <img
                    v-if="filters.Status"
                    src="@/assets/icons/icon-close.svg"
                    class="clients-table__table-filter-icon"
                    @click="$event.stopPropagation(); setFilter('Status', null)"
                  >
                </template>
              </FloatSelector>
            </template>
          </TableHeaderFilterSelector>
        </template>
        <template slot-scope="scope">
          <div class="clients-table__table-cell">
            <div 
              class="clients-table__table-status"
              :class="{
                'clients-table__table-status_active': scope.row.Status === StatusType.ACTIVE,
                'clients-table__table-status_registration-required': scope.row.Status === StatusType.REGISTRATION_REQUIRED,
                'clients-table__table-status_registration-error': scope.row.Status === StatusType.REGISTRATION_ERROR,
                'clients-table__table-status_registering': scope.row.Status === StatusType.REGISTERING,
              }"
            >{{ Status[scope.row.Status] }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <TableHeaderFilterSelector class="clients-table__table-header-cell-filter" :isOpen="!!filters.Inn">
            <template #content>
              <div>Инн/заказчик</div>
            </template>
            <template #options>
              <div class="clients-table__table-filter form-input">
                <input 
                  :value="filters.Inn"
                  type="text"
                  @input="debounceFilterInn('Inn', $event.target.value)" 
                >
              </div>
            </template>
          </TableHeaderFilterSelector>
        </template>
        <template slot-scope="scope">
          <div class="clients-table__table-cell">
            <div><b>{{ scope.row.Name }}</b></div>
            <div><b>{{ scope.row.Inn }}</b></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="clients-table__table-header-cell">
            Тип  
          </div>  
        </template>
        <template slot-scope="scope">
          <div class="clients-table__table-cell">{{ LegalForms[scope.row.LegalForm] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="!clientsIsLoading && !getClients.length && !hasActiveFilter" class="clients-table__placeholder">
      <div class="clients-table__placeholder-image-wrapper">
        <img 
          class="clients-table__placeholder-image" 
          :src="getPlaceholderPicture" 
          alt="img" 
          title="img"
        >
      </div>
      <div class="clients-table__placeholder-title">
        Начните работу с оформления Заказчика
      </div>
      <ButtonElement 
        class="clients-table__placeholder-button" 
        :text="'Добавить заказчика'"
        @click-button="openDialogAddingCustomer" 
      />
    </div>
    <div class="clients-table__pagination">
      <el-pagination
        v-if="pagination && pagination.total >= pagination.pageSize && !clientsIsLoading"
        layout="prev, pager, next"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :current-page="pagination.currentPage"
        @current-change="setPagination({ ...pagination, currentPage: $event })"
      />
    </div>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/ButtonElement";
import { mapState, mapMutations } from "vuex"
import { Status, StatusType, LegalForms, Tabs } from "@/utils/clients"
import { debounce } from "@/utils/helpers"
import FloatSelector from "@/components/sort/FloatSelector";
import TableHeaderFilterSelector from "@/components/sort/TableHeaderFilterSelector";

export default {
  name: 'Clients',
  components: {
    TableHeaderFilterSelector,
    ButtonElement,
    FloatSelector
  },
  data() {
    return {
      LegalForms,
      Status,
      StatusType,
    }
  },
  computed: {
    ...mapState('Clients', ['tableComponentKey', 'clients', 'clientsIsLoading', 'filters', 'pagination']),
    getClients() {
      const clients = [...this.clients]
      return clients.splice(
        (this.pagination.currentPage - 1) * this.pagination.pageSize, 
        this.pagination.currentPage * this.pagination.pageSize
      )
    },
    getPlaceholderPicture() {
      return require(`@/assets/images/accounts-default.svg`)
    },
    hasActiveFilter() {
      return Object.values(this.filters).some((filterValue) => filterValue)
    }
  },
  methods: {
    ...mapMutations('Clients', [
      'increaseTableComponentKey', 
      'clearFilters', 
      'setPagination',
      'clearPagination'
    ]),
    openDialogAddingCustomer() {
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddClient' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    toDetail(item) {
      this.$router.push({
        query: {
          view: Tabs.FINAL_CONTRACTS,
          clientId: item.Id
        }
      })
    },
    fetchClients() {
      this.$store.dispatch('Clients/getClients', {
        ...this.filters
      }).then((clients) => {
        this.increaseTableComponentKey()
        
        this.setPagination({
          pageSize: 20,
          total: clients.length,
          currentPage: 1,
        })
      })
    },
    setFilter(filterName, filterValue) {
      this.$store.commit('Clients/setFilter', {
        filterName,
        filterValue
      })
      this.fetchClients()
    },
  },
  beforeDestroy() {
    this.clearFilters()  
    this.clearPagination()
    this.fetchClients()
  },
  mounted() {
    this.fetchClients()
  },
  async created() {
    this.debounceFilterInn = debounce(this.setFilter, 300)
    // clearQuery('clientId', this.$route.query, this.$router)
    // clearQuery('finalContractId', this.$route.query, this.$router)
    // clearQuery('initialContractId', this.$route.query, this.$router)
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.clients-table {
  overflow-x: auto; 
  
  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .el-pagination .btn-next,
    .el-pagination .btn-prev,
    .el-pager li {
      background: transparent;
    }
  }

  &__table {
    &-header-cell-filter {
      height: 100%;
      min-height: 5rem;
    }

    &-header-cell {
      background: $gray8;
      color: $gray2;
      padding: .9rem 1.2rem;
      font-size: 1.4rem;
      line-height: 1.6rem;
      border-radius: .4rem;
      min-height: inherit;
      height: 100%;
      min-height: 5rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    &-filter {
      margin-top: 1rem;
    }

    &-filter-icon {
      cursor: pointer;
      z-index: 1;
      width: 1.6rem;
      height: 1.6rem;
    }

    &-cell {
      padding: 0 10px;
    }
    
    &-status {
      border-radius: .4rem;
      padding: .2rem .8rem;
      font-size: 1.4rem;
      color: #646D79;
      width: max-content;

      &_active {
        color: $green2;
        background: $green3;
      }
      &_registration-required {
        color: $blue2;
        background: $blue3;
      }
      &_registration-error {
        color: $redClassic;
        background: $red2;
      }
      &_registering {
        background: $primary;
        color: $basicWhite;
      }
    }
  }


  &__placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8.2rem;

    &-button {
      padding: 0 6.8rem;
    }

    &-image {
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: 6.4rem;
    }

    &-title {
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.3rem;
      text-align: center;
      color: $basicBlack;
      margin-bottom: 5.4rem;
    }
  }
}
</style>

<style lang="scss">
.clients-table__table .el-table__row  {
  cursor: pointer;
}

.clients-table__table .el-table__header-wrapper th.el-table__cell {
  vertical-align: top;
}

.clients-table__table .el-table__body-wrapper tr td {
  text-align: left;
}

.clients-table {
  &__table {
    &-filter .float-selector__value {
      color: var(--black);
    }
    &-filter .float-selector__title {
      font-size: 1.6rem;
    }
    &-filter .selector__content {
      width: 100%;
    }
  }
}
</style>