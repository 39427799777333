<template>
  <div class="profile-page page">
    <div class="profile-page__title">
      {{ $t('profile.title') }}
    </div>
    <div v-if="activeAccount" class="profile-page__info">
      <div v-if="user && (role === 'user' || role === 'manager' || role === '' || !activeAccount.is_legal)" class="profile-page__item">
        <div class="profile-page__item-title">
          {{ $t('profile.credentials') }}
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.email') }}
          </div>
          <div class="profile-page__item-value" v-text="user.email" />
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            ID
          </div>
          <div v-if="role === 'partner'" class="profile-page__item-value" v-text="user.id" />
          <div v-else class="profile-page__item-value" v-text="activeAccount.id" />
        </div>
      </div>
      <div v-if="!activeAccount.is_legal" class="profile-page__item">
        <div class="profile-page__item-title">
          {{ $t('profile.personal_data') }}
          <button class="profile-page__item-edit"  @click="editUser">
            <img :src="getPic(locale)" alt="Edit">

          </button>
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.full_name') }}
          </div>
          <div v-if="role === 'partner'" class="profile-page__item-value">
            {{ activeAccount.name }}
          </div>
          <div v-else class="profile-page__item-value">
            {{ activeAccount.last_name + ' ' + activeAccount.first_name + ' ' + `${locale==='RU'?activeAccount.patronymic:''}` }}
          </div>
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.email') }}
          </div>
          <div class="profile-page__item-value" v-text="activeAccount.email" />
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.phone_number') }}
          </div>
          <div class="profile-page__item-value" v-text="activeAccount.phone" />
        </div>
      </div>
      <div v-if="!activeAccount.is_legal" class="profile-page__item">
        <div class="profile-page__item-title">
          {{ $t('profile.docs') }}
          <button class="profile-page__item-edit" @click="editUser">
            <img :src="getPic(locale)" alt="Edit">
          </button>
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.get_checks') }}
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.receiving_checks_method === 'mail' ? $t('profile.on_mail') : $t('profile.on_sms') }}
          </div>
        </div>
      </div>
      <!-- Legal entity data -->
      <div v-if="activeAccount.is_legal" class="profile-page__item">
        <div class="profile-page__item-title">
          {{ $t('profile.legal_data') }}
          <button class="profile-page__item-edit" @click="editUser">
            <img :src="getPic(locale)" alt="Edit">
          </button>
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.inn') }}
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.inn }}
          </div>
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.title_name') }}
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.legal_name }}
          </div>
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
           {{ $t('profile.legal_address') }}
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.legal_address }}
          </div>
        </div>
        <div v-if="locale==='RU'" class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.mail_address') }}
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.legal_receiving_physical_address }}
          </div>
        </div>
      </div>
      <!-- Authorized representative -->
      <div v-if="activeAccount.is_legal && locale==='USA'" class="profile-page__item">
        <div class="profile-page__item-title">
          {{ $t('profile.authorized_representative') }}
          <button class="profile-page__item-edit" @click="editUser">
            <img :src="getPic(locale)" alt="Edit">
          </button>
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.first_name') }}
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.contract_signature_firstname }}
          </div>
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.last_name') }}
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.contract_signature_lastname }}
          </div>
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.position') }}
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.contract_signature_position }}
          </div>
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.based_position') }}
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.contract_signature_document }}
          </div>
        </div>
      </div>
      <!-- Contact person -->
      <div v-if="activeAccount.is_legal" class="profile-page__item">
        <div class="profile-page__item-title">
          {{ $t('profile.contact_person') }}
          <button class="profile-page__item-edit" @click="editUser">
            <img :src="getPic(locale)" alt="Edit">
          </button>
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.name') }}
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.legal_contact_name }}
          </div>
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.phone_number') }}
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.phone }}
          </div>
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            E-mail
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.email }}
          </div>
        </div>
      </div>
      <!-- Bank details -->
      <div v-if="activeAccount.is_legal" class="profile-page__item">
        <div class="profile-page__item-title">
          {{ $t('profile.bank_details') }}
          <button class="profile-page__item-edit" @click="editUser">
            <img :src="getPic(locale)" alt="Edit">
          </button>
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{locale==='RU' ? $t('profile.title_name') : $t('profile.bank_name')}}
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.bank_name }}
          </div>
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.bic') }}
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.bic }}
          </div>
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.сhecking_account') }}
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.iban }}
          </div>
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.cor_account') }}
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.swift_code }}
          </div>
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.bank_address') }}
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.bank_address }}
          </div>
        </div>
      </div>
      <!-- Closing docs -->
      <!-- <div v-if="activeAccount.is_legal" class="profile-page__item">
        <div class="profile-page__item-title">
          {{ $t('profile.closing_docs') }}
          <button class="profile-page__item-edit" @click="editUser">
            <img :src="getPic(locale)" alt="Edit">
          </button>
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.get_with') }}
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.legal_receiving_docs_method === 'by_email' ? $t('profile.send_by_mail') : $t('profile.edi') }}
          </div>
        </div>
        <div v-if="activeAccount.legal_receiving_docs_method === 'by_email'" class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.index') }}
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.legal_receiving_mail_index }}
          </div>
        </div>
        <div v-if="activeAccount.legal_receiving_docs_method === 'by_email'" class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.address') }}
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.legal_receiving_mail_address }}
          </div>
        </div>
        <div v-if="activeAccount.legal_receiving_docs_method === 'by_email'" class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.recipient') }}
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.legal_receiving_mail_recipient }}
          </div>
        </div>
      </div> -->
      <!-- Ba settings -->
      <!-- <div v-if="(role === 'manager' || role === 'finance') && activeAccount.tracker_manager_id" class="profile-page__item">
        <div class="profile-page__item-title">
          {{ $t('profile.business_account_settings') }}
          <button class="profile-page__item-edit" @click="editUser">
            <img :src="getPic(locale)" alt="Edit">
          </button>
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            {{ $t('profile.manager_id') }}
          </div>
          <div class="profile-page__item-value">
            {{ activeAccount.tracker_manager_id }}
          </div>
        </div>
      </div> -->
      <!-- Api management -->
      <div v-if="activeAccount.oaut2_data && activeAccount.access_api" class="profile-page__item">
        <div class="profile-page__item-title">
          {{ $t('profile.api_management') }}
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            ID
          </div>
          <div class="profile-page__item-value" v-text="activeAccount.oaut2_data.cilent_id" />
        </div>
        <div class="profile-page__item-row">
          <div class="profile-page__item-name">
            Client secret
          </div>
          <div class="profile-page__item-value" v-text="activeAccount.oaut2_data.cilent_secret" />
          <ButtonElement class="profile-page__item-btn" :text="$t('button_props.key_generate')" view="empty" @click-button="generateNewKey" />
        </div>
      </div>
      <div class="profile-page__item change-password">
        <div class="change-password__wrap">
            <div class="change-password__title">{{ $t('change_password.title') }}</div>
            <div class="change-password__change-password">{{ $t('change_password.change') }}</div>
            <!-- Old password -->
            <div class="form-input"
              :class="
              [
                {'form-input--full' : form.current_password},
                {'form-input--error' : errors.current_password || (authErrors && authErrors.current_password)}
              ]"
            >
              <input
                v-model="form.current_password"
                :type="isHideOldPassword ? 'password' : 'text'"
              >
              <span class="form-input__label">
                {{$t('change_password.old')}}
              </span>
              <div class="form-input__icon" @click="isHideOldPassword=!isHideOldPassword">
                <img v-if="isHideOldPassword" src="@/assets/icons/icon-eye-closed.svg">
                <img v-else src="@/assets/icons/icon-eye.svg">
              </div>
              <div v-if="errors.current_password" class="form-input__error" v-text="errors.current_password" />
              <div v-if="authErrors && authErrors.current_password" class="form-input__error">
                <p v-for="(err, index) in authErrors.current_password" :key="index">
                  {{ err }}
                </p>
              </div>
            </div>
            <!-- New password -->
            <div class="form-input"
              :class="
              [
                {'form-input--full' : form.password},
                {'form-input--error' : errors.password || (authErrors && authErrors.password)}
              ]"
            >
              <input
                v-model="form.password"
                :type="isHidePassword ? 'password' : 'text'"
                @focus="checkingPassword = true"
                @blur="checkingPassword = false"
              >
              <span class="form-input__label">
                {{$t('change_password.new')}}
              </span>
              <div class="form-input__icon" @click="isHidePassword=!isHidePassword">
                <img v-if="isHidePassword" src="@/assets/icons/icon-eye-closed.svg">
                <img v-else src="@/assets/icons/icon-eye.svg">
              </div>
              <div v-if="errors.password" class="form-input__error" v-text="errors.password" />
              <div v-if="authErrors && authErrors.password" class="form-input__error">
                <p v-for="(err, index) in authErrors.password" :key="index">
                  {{ err }}
                </p>
              </div>
              <div v-if="checkingPassword" class="form-input__info">
                <div
                  class="form-input__info-item"
                  :class="{'form-input__info-item--success' : form.password && form.password.length > 5}"
                >
                  {{$t('registration.minimum_symbols')}}
                </div>
                <div
                  class="form-input__info-item"
                  :class="{'form-input__info-item--success' : isContainNumber}"
                >
                  {{$t('registration.minimum_numbers')}}
                </div>
                <div
                  class="form-input__info-item"
                  :class="{'form-input__info-item--success' : isContainLetter}"
                >
                  {{$t('registration.minimum_letters')}}
                </div>
                <div
                  class="form-input__info-item"
                  :class="{'form-input__info-item--success' : isContainBigLetter}"
                >
                {{$t('registration.minimum_capital_letters')}}
                </div>
              </div>
            </div>
            <!-- Repeat password -->
            <div class="form-input"
              :class="
              [
                {'form-input--full' : form.password_confirmation},
                {'form-input--error' : errors.password_confirmation || (authErrors && authErrors.password_confirmation)}
              ]"
            >
              <input 
                v-model="form.password_confirmation" 
                :type="isHideRepeatPassword ? 'password' : 'text'"
                >
              <span class="form-input__label">
                {{$t('change_password.repeat')}}
              </span>
              <div class="form-input__icon" @click="isHideRepeatPassword=!isHideRepeatPassword">
                <img v-if="isHideRepeatPassword" src="@/assets/icons/icon-eye-closed.svg">
                <img v-else src="@/assets/icons/icon-eye.svg">
              </div>
              <div v-if="errors.password_confirmation" class="form-input__error" v-text="errors.password_confirmation" />
              <div v-if="authErrors && authErrors.password_confirmation" class="form-input__error">
                <p v-for="(err, index) in authErrors.password_confirmation" :key="index">
                  {{ err }}
                </p>
              </div>
            </div>
            <div v-if="errors.same_password" class="form-input__error" v-text="errors.same_password" />
              <div v-if="authErrors && authErrors.same_password" class="form-input__error">
                <p v-for="(err, index) in authErrors.same_password" :key="index">
                  {{ err }}
                </p>
              </div>
            <ButtonElement
              :text="$t('button_props.change_password')"
              @click-button="changePassword"
            />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from '@/components/elements/ButtonElement'

export default {
  name: 'Profile',
  components: {
    ButtonElement,
  },
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
      form: {
        current_password: null,
        password: null,
        password_confirmation: null
      },
      errors:{
        current_password: null,
        password: null,
        password_confirmation: null,
        same_password: null
      },
      checkingPassword: false,
      isHideOldPassword: true,
      isHidePassword: true,
      isHideRepeatPassword: true,
      isContainNumber: false,
      isContainLetter: false,
      isContainBigLetter: false
    }
  },
  computed: {
    user() {
      return this.$store.state.Auth.user
    },
    role() {
      return this.$store.state.Auth.role
    },
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    currentPassword() {
      return this.form.password
    },
    authErrors() {
      return this.$store.state.Auth.errors
    },
    isError() {
      let error = false
      for (const key in this.errors) {
        if (this.errors[key]) error = true
      }
      return error
    }
  },
  methods: {
    getPic(locale) {
      if(locale==='RU') {
        return  require("@/assets/icons/icon-edit.svg")
      } else {
        return require("@/assets/icons/icon-edit-red.svg")
      }
    },
    generateNewKey() {
      this.$store.dispatch('Auth/getNewKey', this.activeAccount.id)
    },
    editUser() {
      this.$store.commit('Dialog/setData', { label: 'isEditable', data: true })
      this.$store.commit('Dialog/setData', { label: 'isOpenUL', data: true })
      if (this.activeAccount.is_legal) {
        this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'BusinessAccountData' })
      } else {
        this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'UserData' })
      }
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    validateForm () {
      if (!this.form.current_password) {
        this.errors.current_password = this.$t('registration.input_password')
      }
      if (!this.form.password) {
        this.errors.password = this.$t('registration.input_password')
      }
      if (!this.form.password_confirmation) {
        this.errors.password_confirmation = this.$t('registration.password_repeat')
      } else if (this.form.password_confirmation !== this.form.password) {
        this.errors.password_confirmation = this.$t('registration.passwords_dont_match')
      }
      if (this.form.current_password === this.form.password) {
        this.errors.same_password = this.$t('registration.same_password')
      }
    },
    changePassword() {
      this.validateForm ()
      if (this.isError) {
        return false
      }
      const data = this.form
      this.$store.dispatch('Auth/changePassword', data)
      .then(()=> {
        this.$notify({
                  message: this.$t('change_password.success'),
                  type: 'success'
        })
        this.form.current_password = null
        this.form.password = null
        this.form.password_confirmation = null
      })
      .catch(err => {
        console.log(err)
        this.$notify({
                  message: this.$t('change_password.error'),
                  type: 'error'
        })
      })
    }
  },
  watch: {
    currentPassword(val) {
      const regNumber = /^(?=.*\d).+$/
      this.isContainNumber = regNumber.test(String(val))
      const regLetter = /^(?=.*[a-z]).+$/
      this.isContainLetter = regLetter.test(String(val))
      const regBigLetter = /^(?=.*[A-Z]).+$/
      this.isContainBigLetter = regBigLetter.test(String(val))
    },
    form: {
      deep: true,
      handler () {
        this.$store.commit('Auth/setData', { label: 'errors', data: null })
        for (const key in this.errors) {
          this.errors[key] = null
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.profile-page {
  &__title {
    font-size: 3rem;
    font-weight: 700;
    line-height: 3.5rem;
    margin-bottom: 3rem;
  }
  .change-password {
    padding-top: 3.2rem;
    &__title {
      font-family: 'Roboto';
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #333;
      margin-bottom: 2rem;
    }
    &__change-password {
      font-family: 'Roboto';
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #333;
    }
    &__wrap {
      max-width: 400px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .button {
        padding: 13px 94px;
        min-height: 5rem;
        margin-top: 1.7rem;
      }
    }
  }

  &__item {
    margin-bottom: 4rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-title {
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1.9rem;
      margin-bottom: 2rem;
    }

    &-edit {
      background: none;
      border: none;
      margin-left: 1.4rem;

      img {
        display: block;
      }
    }

    &-row {
      display: flex;
      align-items: flex-start;
      font-size: 1.6rem;
      line-height: 1.9rem;
      margin-bottom: 1.8rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      .button {
        padding: 0 2rem;
        margin-left: 1rem;
      }
    }

    &-name {
      color: $grey1;
      // width: 14rem;
      padding-right: 2rem;
    }

    &-value {
      color: $basicBlack;
    }

    &-btn.button {
      height: auto;
      background-color: transparent;
      border-top: none;
      border-right: none;
      border-bottom: 1px solid var(--primary);
      border-left: none;
      border-radius: 0;
      outline: none;
      padding: 0;
      margin-left: 2rem;
      color: var(--primary);

      &:hover, &:focus {
        color: var(--primary);
        background-color: transparent;
        border-bottom: 1px solid transparent;
      }
    }
  }
}

@include below(769px) {
  .profile-page__item-row{
    flex-direction: column;
    .profile-page__item-btn{
      margin-left: 0;
    }
  }
  .profile-page__item-name{
    width: 100%;
    padding-right: 0;
    margin-bottom: 5px;
  }
}
</style>
