<template>
  <div class="manager-layout" :class="{'locale-usa': locale === 'USA'}">
    <div class="manager-layout__header">
      <div class="manager-layout__header--left">
        <div
            class="manager-layout__header-burger"
            :class="{ 'app-header__burger--close': showMobileMenu }"
            @click="burgerClick"
        >
          <svg class="open" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="14" width="24" height="4" rx="1" fill="#333333"/>
            <rect x="4" y="3" width="24" height="4" rx="1" fill="#333333"/>
            <rect x="4" y="25" width="24" height="4" rx="1" fill="#333333"/>
          </svg>

          <svg class="close" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="8.92896" y="6.10051" width="24" height="4" rx="1" transform="rotate(45 8.92896 6.10051)" fill="#333333"/>
            <rect x="6.10059" y="23.0711" width="24" height="4" rx="1" transform="rotate(-45 6.10059 23.0711)" fill="#333333"/>
          </svg>
        </div>
 
        <div class="manager-layout__header-logo">
          {{$t('manager_layout.admin_panel')}}
        </div>
        <div class="manager-layout__header-nav">
          <router-link v-if="role === 'manager' || role === 'finance' || role === 'admin'" class="manager-layout__header-nav-item" :to="{name: 'Clients'}">
            {{$t('manager_layout.header-nav.0')}}
          </router-link>
          <router-link v-if="role === 'manager' || role === 'finance' || role === 'admin'" class="manager-layout__header-nav-item" :to="{name: 'ClientsInfo'}">
            {{$t('manager_layout.header-nav.1')}}
          </router-link>
          <router-link v-if="role === 'admin'" class="manager-layout__header-nav-item" :to="{name: 'Reports'}">
            {{$t('manager_layout.header-nav.2')}}
          </router-link>
          <router-link v-if="role === 'admin'|| role === 'finance'|| role === 'manager'" class="manager-layout__header-nav-item" :to="{name: 'ActivePromo'}">
            {{$t('manager_layout.header-nav.3')}}
          </router-link>
          <router-link v-if="role === 'admin'" class="manager-layout__header-nav-item" :to="{name: 'FAQ'}">
            {{$t('manager_layout.header-nav.4')}}
          </router-link>
          <!-- <router-link v-if="role === 'manager' || role === 'finance' || role === 'admin'" class="manager-layout__header-nav-item" :to="{name: 'Representatives'}">
            {{$t('manager_layout.header-nav.5')}}
          </router-link> -->
          <!-- <button @click="changeLang">{{ $store.getters.language === 'en'?'ru':'en' }}</button>  -->
          
        </div>
      </div>
      <div class="manager-layout__prof-lang-container">
        <div class="manager-layout__header--right">
          <ProfileCard />
        </div>
      </div>
     

      <el-dialog
          class="mobile-menu"
          :class="{ 'mobile-menu--show': showMobileMenu }"
          :visible.sync="showMobileMenu"
          :modal="false"
          :fullscreen="true"
      >
        <div class="mobile-menu__body">
          <ul class="mobile-menu__list">
            <li class="mobile-menu__list-item">
              <router-link :to="{name: 'Clients'}">
                {{$t('manager_layout.header-nav.0')}}
              </router-link>
            </li>

            <li class="mobile-menu__list-item">
              <router-link :to="{name: 'ClientsInfo'}">
                {{$t('manager_layout.header-nav.1')}}
              </router-link>
            </li>
          </ul>
        </div>
      </el-dialog>
    </div>
    <slot />
    <Dialog />
  </div>
</template>

<script>

export default {
  name: 'ManagerLayout',
  components: {
    ProfileCard: () => import('@/components/profile/ProfileCard'),
    Dialog: () => import('@/components/blocks/Dialog'),
  },
  data () {
    return {
      locale: process.env.VUE_APP_LOCALE,
      showMobileMenu: false,
    };
  },
  computed: {
    role() {
      return this.$store.state.Auth.role
    },
  },
  methods: {
    changeLang() {
      this.$store.commit('changeLang')
      this.$i18n.locale = this.$store.getters.language;
    },
    
    burgerClick() {
      this.showMobileMenu = !this.showMobileMenu
      if(this.showMobileMenu == true) {
        this.open();
      } else {
        this.close();
      }
    },
    open () {
      document.body.classList.add('el-popup-parent--hidden')
    },
    close() {
      document.body.classList.remove('el-popup-parent--hidden')
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/helpers/mixin';
@import '@/assets/styles/helpers/variables';

.manager-layout {
  &.locale-usa {
    .manager-layout {
      &__header {
        &--left {
          font-family: 'Avenir Next Cyr';
        }
      }
    }
  }
  &__header {
    background: #FFFFFF;
    box-shadow: 0 1px 0 #E3E6EC;
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4rem;

    &-logo {
      margin-right: 7rem;
    }

    &--left {
      display: flex;
      align-items: center;
    }

    &-nav {
      &-item {
        font-weight: 500;
        font-size: 1.6rem;
        color: $text;
        position: relative;
        margin-right: 4.5rem;

        &::before {
          content: '';
          display: inline-block;
          width: 100%;
          height: 0;
          border-radius: 5px;
          background: var(--primary);
          position: absolute;
          left: 0;
          bottom: -2.7rem;
          transition: 0.3s ease-out;
        }

        &.router-link-active {
          &::before {
            height: 3px;
          }
        }

        &:hover {
          &::before {
            height: 3px;
          }
        }

        &.disabled {
          display: inline-block;
          cursor: default;
          opacity: 0.7;

          &::before {
            display: none;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &-logo {
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2rem;
      color: var(--primary);
    }

    &-burger {
      width: 24px;
      height: 24px;
      position: relative;
      cursor: pointer;
      display: none;
      margin-right: 16px;

      svg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        transition: opacity .4s, transform .4s;

        &.close {
          transform: translate3d(-50%, -50%, 0) scale(0);
          opacity: 0;
        }
      }

      &--close {

        svg.open {
          transform: translate3d(-50%, -50%, 0) scale(0);
          opacity: 0;
        }

        svg.close {
          transform: translate3d(-50%, -50%, 0) scale(1);
          opacity: 1;
        }
      }
    }

    @include below(769px) {
      padding: 16px;

      &-logo {
        font-size: 16px;
        line-height: 1.4;
        margin-right: 24px;
      }

      &-nav {

        &-item {
          font-size: 14px;
          line-height: 1.4;
          margin-right: 24px;
        }
      }
    }

    @include below(576px) {

      &-logo {
        margin-right: 0;
      }

      &-nav {
        display: none;
      }

      &-burger {
        display: flex;
      }
    }
  }
  &__prof-lang-container {
    display: flex;
  }
  .mobile-menu {
    top: 71px;
    background: rgba(255, 255, 255, 0.96);

    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px;
    }

    &__list {
      display: flex;
      flex-direction: column;
      align-items: center;
      list-style: none;
      padding: 0;
      margin: 0 0 32px;

      &-item {
        margin: 0 0 24px;

        a, div {
          font-size: 16px;
          font-weight: 700;
          line-height: 1.4;
          color: $basicBlack;
          text-align: center;
          transition: color .4s;

          &:hover {
            color: var(--primary);
          }
        }

        div {

          &.disabled {
            pointer-events: none;
            cursor: default;
            opacity: 0.7;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    @include below(577px) {
      top: 68px;
    }
  }
}
</style>
