<template>
  <div v-loading="loading" class="support-page page">
    <div class="support-page__title" :class="{'locale-usa': locale === 'USA'}">
      <Title :text="$t('support.title')"/>
    </div>

    <div class="support-page__buttons">
      <div class="support-page__tabs">
        <div 
          class="support-page__tab" 
          :class="{'support-page__tab--active': activeTab === 'open', 'locale-ru': locale === 'RU','locale-usa': locale === 'USA'}"
          @click="activeTab = 'open'"
        >
        {{ $t('support.open_requests') }}
        </div>
        <div 
          class="support-page__tab" 
          :class="{'support-page__tab support-page__tab--active': activeTab === 'archive', 'locale-ru': locale === 'RU','locale-usa': locale === 'USA'}"
          @click="activeTab = 'archive'"
        >
        {{$t('support.archive')}}
        </div>
      </div>
      <ButtonElement
        class="support-page__new-application"
        :text="$t('button_props.create_request')"
        view="empty"
        @click-button="openNewApplicationDialog"
      />
    </div>

    <div class="support-page__table" :class="{'locale-usa': locale === 'USA'}">
      <el-table
        :data="formData"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        @current-change="toDetail"
      >
        <el-table-column :label="$t('support.key')" width="200">
          <template slot-scope="scope">
            <div class="higlited-title" :class="{'open':scope.row.status===1}">{{ scope.row.source_id }}</div>
          </template>
        </el-table-column>

        <el-table-column :label="$t('support.topic')">
          <template slot-scope="scope">
            <div class="support-page__table-row" > 
              <span class="support-page__table-row-theme higlited-title" :class="{'open':scope.row.status===1}">{{ scope.row.theme.name }}: </span> 
              <span class="support-page__table-row-name" :class="{'open':scope.row.status===1}">{{ scope.row.name }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column :label="$t('support.status')" width="250">
          <template slot-scope="scope">
            <div class="support-page__table-status" :class="{'support-page__table-status--info': scope.row.status === 3}">
              {{ lang==='ru'?statuses.ru[scope.row.status]:statuses.en[scope.row.status] }}
            </div>
          </template>
        </el-table-column>
        
        <el-table-column :label="$t('support.updated')" width="150">
          <template slot-scope="scope">
            <div class="higlited-title">
              {{ fixDateFormatTime(scope.row.updated_at) }}   
            </div>
          </template>
        </el-table-column>
        <div class="el-table__empty-block" slot="append" v-if="!formData||formData.length=='0'">
          <p class="el-table__empty-text ">{{locale==='RU'?'Нет данных':'No data' }}</p>
        </div>
      </el-table>
      <div
        v-if="pagination && pagination.total > 15 && pagination.lastPage !== currentPage"
        class="ad-page__accounts-more" 
        :class="{'locale-usa': locale === 'USA'}"
        @click="showMore" 
      >
        <img :src="getPic(locale)" alt="icon">
        {{$t('show_more')}}
      </div>
    </div>
  </div>
</template>

<script>
import Title from "@/components/elements/Title";
import ButtonElement from "@/components/elements/ButtonElement";

import fixDateFormat from '@/mixin/fixDate';

const tableRowClassName = ({
  row
}) => {
  // console.log(row)
  if (row.has_new_comment ) {
    return 'higlited'
  }
  return ''
}

export default {
  name: 'Support',
  components: {
    Title,
    ButtonElement,
  },
  mixins: [fixDateFormat],
  data () {
    return {
      locale: process.env.VUE_APP_LOCALE,
      lang: this.$i18n._vm.locale,
      colors: {},
      tableRowClassName,
      loading: false,
      activeTab: 'open',
      currentPage: 1,
      formData: [],
      statuses: {
       'ru': {
          1: "Открыт",
          2: "В работе",
          3: "Требуется информация",
          4: "Закрыт"
        },
        'en': {
          1:  "Open",
          2:  "In processing",
          3: "Information required",
          4: "Closed"
        },
      }
      
    }
  },
  computed: {
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    helpdesk() {
      return this.$store.state.Support.helpdesk
    },
    pagination() {
      return this.$store.state.Support.helpdeskPagination
    },
    applicationChanget() {
      return this.$store.state.Support.applicationChanget
    },
    getLang() {
      let language = this.$i18n._vm.locale
      return language
    }
  },
  watch: {
    getLang: {
      deep: true,
      handler(val) {
        this.dataPickerforceRerender(val)
      }
    },
    activeAccount: {
      deep: true,
      handler(val) {
        if (val) {
          this.formData = []
          this.currentPage = 1
          this.getHelpdesk()
        }
      }
    },
    activeTab: {
      deep: true,
      handler(val) {
        if (val) {
          this.formData = []
          this.currentPage = 1
          this.getHelpdesk()
        }
      }
    },
    applicationChanget(val) {
      if (val) {
        this.activeTab = 'open'
        this.formData = []
        this.currentPage = 1
        this.getHelpdesk()
        this.$store.commit('Support/setData', { label: 'applicationChanget', data: false })
      }
    },
    helpdesk(val) {
      if (val) {
        this.formData = [...this.formData, ...val]
      }
    },
  },
  created() {
    this.getHelpdesk(),
    this.colors = this.$colors();
  },

  mounted() {
    this.$store.dispatch('Auth/getUnreadNotifications')
  },
  methods: {
    getPic(locale) {
      if(locale==='RU') {
        return  require("@/assets/icons/icon-arrow-blue.svg")
      } else {
        return require("@/assets/icons/icon-arrow-red.svg")
      }
    },
    dataPickerforceRerender(value) {
      this.lang = value
    },
    openNewApplicationDialog() {
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'NewApplication' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    getHelpdesk() {
      this.$store.dispatch('Support/getHelpdesk', {
        status : this.activeTab,
        page: this.currentPage,
      })
    },
    toDetail(row) {
      this.$router.push(`/support/${row.id}`)
    },
    showMore() {
      this.currentPage++
      this.getHelpdesk()
    },
  }
}
</script>


<style lang="scss" scoped>
  .el-table__body, .el-table__header {
    width: auto!important;
  }
</style>
<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.el-table__empty-block {
  display: none;
}
.el-table__append-wrapper {
  .el-table__empty-block {
    display: flex;
  }
}

.el-table .higlited {
  background: #F5F7FA;
}

.el-table__row > td {
  background: #fff;
}

.el-table .higlited .higlited-title {
  font-weight: 600;
}



.support-page {

  .el-table__header {
    tr {
      .el-table__cell:nth-last-child(-n+2) {
        text-align: right;
    }
    }
  }
   
  &__title {
    margin-bottom: 3.2rem;
    &.locale-usa {
      .title {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 700;
        font-size: 3.2rem;
        line-height: 4rem;
      }
    } 
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.2rem;
  }
  
  &__new-application {
    height: 50px !important;
    padding: 0 5.1rem;
  }

  &__tabs {
    display: flex;
  }

  &__tab {
    padding: 0 40px;
    height: 50px;
    display: flex;
    align-items: center;
    color: $gray2;
    font-weight: 700;
    cursor: pointer;
    position: relative;

    &.locale-ru {
      background: $gray6;
      box-shadow: 0px 5px 10px rgba(158, 158, 158, 0.1);
      border-radius: 7.64082px;
      margin-right: 8px;
    }

    &.locale-usa {
      font-family: 'Gilroy';
      font-weight: 500;
      font-size: 18px;
      padding: 16px 10.25px;
      min-width: 16.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #9B9FA8;
    }
   
    &.locale-usa::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #9B9FA8;
      top: 100%;
      left: 0;
    }

    &--active{
      &.locale-usa::after  {
        background-color: var(--black);
      }
      &.locale-usa {
        color: var(--black);
      }
      
      &.locale-ru {
        background: $primaryLight;
        color: $basicBlack;
      }
     
    }
  }

  &__table {
    &.locale-usa {
        .el-table {
            .has-gutter {
              color: rgba(var(--black), 0.46);
              font-weight: 500;
            }
            font-weight: 500;
            font-family: 'Gilroy';
            font-style: normal;
            color: var(--black);
            
            .higlited-title {
              font-weight: 500;
              font-size: 1.6rem;
              &.open {
                font-weight: 700;
              }
          }

          padding: 1.6rem 2.4rem 0 2.4rem;
          background-color: #fff;
          &__header {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
          }
      }
    }
    

    .el-table__row  {
      cursor: pointer;
    }

    .el-table__row .el-table__cell {
      padding: 2.4rem 1rem;
    }
    .el-table__row .el-table__cell:last-child {
      text-align: right;
    }
    
  }

  &__table-row {
    display: flex;
    align-items: center;
  }

  &__table-row-theme {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: block;
    margin-right: .5rem;
  }

  &__table-row-name {
    color: #7F858D;
    &.open {
      font-weight: 700;
    }
  }

  &__table-status {
    background: var(--gray);
    border-radius: .4rem;
    padding: .2rem .8rem;
    font-size: 1.4rem;
    color: #646D79;
    width: max-content;
    
    &--info {
      background: #E5EDFC;
      color: #5A6EB6;

    }
  }
}
@include below(600px) {
  .support-page {
    &__buttons {
      flex-direction: column;
      row-gap: 1rem;
      .button {
          order: 1;
      }
    }
    &__tabs {
      order: 2;
    }
  }
}
</style>
