<template>
  <div v-loading="loading" class="vk-page page">
    <div class="vk-page__title" :class="{'locale-usa': locale==='USA'}">
      <Title :text="$t('vk_accounts.add_users')"/>
      <ButtonElement
        v-if="vkObservers && vkObservers.length"
        class="vk-page__no-data-add"
        :text="$t('button_props.add_user')"
        @click-button="openVkUserDialog(false)"
      />
    </div>
    <div class="vk-page__content">
      <div v-if="vkObservers && vkObservers.length" class="vk-page__users">
        <div v-for="user in vkObservers" :key="user.id" class="vk-page__users-item">
          <div class="vk-page__users-item-top">
            <div class="subtitle">{{ $t('user') }}</div>
            <ButtonElement
              class="vk-page__no-data-add"
              :text="$t('button_props.change')"
              @click-button="openVkUserDialog(user)"
            />
          </div>
          <div class="user">{{ user.first_name }} {{ user.last_name }}</div>
          <div class="cabinets">
            <div class="subtitle">{{$t('vk_accounts.rooms')}}</div>
            <div class="selected-accounts">
              <div v-for="item in user.clients" :key="item.id" class="selected-accounts__item">
                <div class="selected-accounts__item-name" v-html="item.name" />
                <div class="selected-accounts__item-email" v-html="item.account_name" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="vk-page__empty">
        <img src="@/assets/images/not-found-vk.svg" class="vk-page__empty-image">
        <p class="vk-page__empty-text">{{$t('vk_accounts.no_additional_users')}}</p>
        <ButtonElement
            class="vk-page__no-data-add"
            :text="$t('button_props.add_user')"
            @click-button="openVkUserDialog(false)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Title from "@/components/elements/Title";
import ButtonElement from "@/components/elements/ButtonElement";

export default {
  name: 'VkAccounts',
  components: {
    Title,
    ButtonElement,
  },
  data () {
    return {
      locale: process.env.VUE_APP_LOCALE,
      loading: false,
    }
  },
  computed: {
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    vkObservers() {
      return this.$store.state.Ad.vkObservers
    }
  },
  watch: {
    activeAccount: {
      deep: true,
      handler(val) {
        if (val) {
          this.getVkAccounts()
        }
      }
    },
  },
  created() {
    this.getVkAccounts()
  },
  methods: {
    openVkUserDialog(edit) {
      this.$store.commit('Ad/setData', { label: 'vkObserverEdit', data: edit })
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddVkUser' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    getVkAccounts() {
      this.$store.dispatch('Ad/getObserver', {
        service : 'vkontakte',
        business_account_id: this.activeAccount.id
      })
    }
    
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.vk-page {
  &__title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5rem;
    &.locale-usa {
      font-family: 'Gilroy';
      font-style: normal;
      .title {
         font-weight: 700;
        font-size: 3.2rem;
      }
    }

    .title {
      margin: 2rem 0;
    }

    .button {
      margin: 0;
    }
  }

  &__empty {
    &-image {
      margin: 10rem auto 6rem;
      display: block;
      max-width: 100%;
    }

    &-text {
      text-align: center;
      font-weight: 700;
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  }

  &__users {
    &-item{
      background: $basicWhite;
      padding: 3rem;
      box-shadow: 0px 4px 12px rgba(192, 192, 192, 0.12);
      border-radius: 6px;
      margin-bottom: 1.4rem;

      &-top {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: -2rem;

        @include below($sm) {
          margin-bottom: 0;
        }

        .button {
          margin: 0;
        }
      }

      .subtitle {
        color: $gray2;
        margin-bottom: .5rem;
      }

      .user {
        font-weight: 700;
        font-size: 2.4rem;
      }

      .cabinets {
        margin-top: 1rem;
      }
    }
  }

  &__no-data-add {
    display: block;
    margin: 0 auto 2rem;
    padding: 0 2.4rem;
    height: 4.5rem !important;
  }
}

</style>
