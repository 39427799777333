export const CreateModesType = {
  DIRECT_CLIENT: 'DirectClient',
  INITIAL_CONTRACT_CLIENT: 'InitialContractClient',
}

export const CreateModes = {
  DirectClient: "Прямой клиент",
  InitialContractClient: "Заказчик или исполнитель по изначальному или исполнитель по расходному договору"
}

export const LegalFormsKeys = {
  JURIDICAL_PERSON: 'JuridicalPerson',
  INDIVIDUAL_ENTREPRENEUR: 'IndividualEntrepreneur',
  PHYSICAL_PERSON: 'PhysicalPerson',
  INTERNATIONAL_JURIDICAL_PERSON: 'InternationalJuridicalPerson',
  INTERNATIONAL_PHYSICAL_PERSON: 'InternationalPhysicalPerson',
}

export const LegalForms = {
  [LegalFormsKeys.JURIDICAL_PERSON]: 'Юр. лицо РФ',
  [LegalFormsKeys.INDIVIDUAL_ENTREPRENEUR]: 'Индивидуальный предприниматель РФ',
  [LegalFormsKeys.PHYSICAL_PERSON]: 'Физ. лицо РФ',
  [LegalFormsKeys.INTERNATIONAL_JURIDICAL_PERSON]: 'Иностранное юр. лицо',
  [LegalFormsKeys.INTERNATIONAL_PHYSICAL_PERSON]: 'Иностранное физ. лицо',
}

export const Status = {
  RegistrationRequired: 'Ожидает регистрации',
  Registering: 'Регистрация',
  Active: 'Активный',
  RegistrationError: 'Ошибка регистрации'
}

export const StatusType = {
  REGISTRATION_REQUIRED: 'RegistrationRequired',
  REGISTERING: 'Registering',
  ACTIVE: 'Active',
  REGISTRATION_ERROR: 'RegistrationError'
}

export const Tabs = {
  CLIENTS: 'clients',
  CONTRACTS: 'contracts',
  FINAL_CONTRACTS: 'final-contracts',
  INITIAL_CONTRACTS: 'initial-contracts',
  CREATIVES: 'creatives',
  ACTS: 'acts',
}