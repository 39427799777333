export default {
    install(Vue) {
        Vue.prototype.$colors = () => {
            return {
                primary: '#FF7F50',
                primaryLight:"#fda382",
                primaryHover: "#ed794e",
                black: '#343434',
                gray: '#F6F7F9',
                darkGray: '#7F858D',
                bgGray: '#E5E5E5'
            }
        }
    }
}