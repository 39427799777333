<template>
    <div class="representatives-info" :class="{'locale-usa': locale === 'USA'}">
      <div class="container">
        <div class="representatives-info__header">
          <div class="representatives-info__title">
            {{$t('representatives.title')}}
          </div>
          <div v-if="clientRole ==='user' || clientRole ==='manager'" class="users__add" @click="addNewRepresentative">
            + {{$t('representatives.add_representative')}}
          </div>
        </div>
        <!-- <div class="representatives-info__filters">
          <Search placeholder="Email" @findValue="changeSearchText" />
          <Selector
            v-if="clientRole==='admin'"
            :title="$t('users.role')"
            :options="locale==='USA'? clientTypesUSA:clientTypes"
            @handleChange="getNewData"
          />
          <button :title="$t('button_props.generate_report')" class="representatives-info__download"
            @click="getAllUsersList()"></button>
        </div> -->
        <div v-if="representativesList && representativesList.length" class="representatives-info__table" v-loading="loading">
          <el-table :data="representativesList" :row-class-name="tableRowClassName" style="width: 100%" :fit="true">
            <el-table-column width="330" :label="$t('representatives.name')" prop="name" />
            <el-table-column
              v-if="clientRole==='admin'"
              prop="role_ru"
              :label="$t('representatives.role')"
              width="120"
            >
            <template slot-scope="scope">
              {{ scope.row.role }}
            </template>
            </el-table-column>
            <el-table-column :label="$t('representatives.email')">
              <template v-if="scope.row.email ? scope.row.email : ''" slot-scope="scope">
                <a :href="'mailto:' + scope.row.email">
                  {{ scope.row.email }}
                </a>
              </template>
            </el-table-column>
            <el-table-column width="190">
              <template slot-scope="scope">
                <span v-if="scope.row.is_active === 0" class="representatives-info__table-deactivated">{{ $t('users.deactivated') }}</span>
                <button v-else class="representatives-info__table-button" 
                @click="editRepresentative(scope.row.id)"
                v-html="$t('edit')"
                >
                </button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-if="representativesList && !representativesList.length">
          {{ $t('nothing_found') }}
        </div>
        <div class="representatives-info__pagination">
          <el-pagination v-if="pagination && pagination.total>=10" layout="prev, pager, next" :total="pagination.total"
            :page-size="10" :current-page="pagination.currentPage" @current-change="getNewPage" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
//   import Search from "@/components/sort/Search";
//   import Selector from "@/components/sort/Selector";
  import fixDateFormat from '@/mixin/fixDate'
  
  export default {
    name: 'Representatives',
    components: {
    //   Search,
    //   Selector
    },
    mixins: [fixDateFormat],
    data() {
      return {
        locale: process.env.VUE_APP_LOCALE,
        loading: false,
        params: {
          // role: 'partner',
          per_page: 10,
        },
        role: {
          finance: 'Финансист',
          admin: 'Админ',
          manager: 'Менеджер',
          user: 'Клиент',
          representative: 'Представитель'
        },
        roleUSA: {
          finance: 'Financier',
          admin: 'Admin',
          manager: 'Manager',
          user: 'Client',
          representative: 'Representative',
        },
        clientTypes: [
          {
            id: 0,
            name: 'Все'
          },
          {
            id: 'finance',
            name: 'Финансист'
          },
          {
            id: 'manager',
            name: 'Менеджер'
          },
          {
            id: 'admin',
            name: 'Админ'
          },
          {
            id: 'user',
            name: 'Клиент'
          },
          {
            id: 'representative',
            name: 'Представитель'
          }
        ],
        clientTypesUSA: [
          {
            id: 0,
            name: 'All'
          },
          {
            id: 'finance',
            name: 'Financier'
          },
          {
            id: 'manager',
            name: 'Manager'
          },
          {
            id: 'admin',
            name: 'Admin'
          },
          {
            id: 'user',
            name: 'Client'
          },
          {
            id: 'representative',
            name: 'Representative'
          }
        ],
      }
    },
    computed: {
      representativesAllList() {
        return this.$store.state.Representatives.representativesAllList
      },
      representativesList() {
        return this.$store.state.Representatives.representativesList
      },
      pagination() {
        return this.$store.state.Representatives.pagination
      },
      clientRole() {
        return this.$store.state.Auth.user.role
      },
      customCellClass() {
        return 'partner-column'
      },
      activeAccount() {
        return this.$store.state.Auth.activeAccount
      }
    },
    watch: {
      $route() {
        for (let key in this.$route.query) {
          this.params[key] = this.$route.query[key]
        }
        this.getData()
      }
    },
    created() {
      // this.$store.commit('Auth/setData', { label: 'activeAccount', data: null })
      for (let key in this.$route.query) {
        this.params[key] = this.$route.query[key]
      }
      this.$nextTick(() => {
        this.getData()
        })
    },
    methods: {
      addNewRepresentative() {
        this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddEditRepresentative' })
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
      },
      editRepresentative(id) {
        let representative = this.$store.state.Representatives.representativesList.filter(el => el.id === id)[0]
        this.$store.commit('Representatives/setData', { label: 'editRepresentative', data: representative })
        this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddEditRepresentative' })
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
      },
      async getData() {
        this.loading = true
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : localStorage.getItem('client_token') 
        console.log('token representative', token)
        await this.$store.dispatch('Representatives/getRepresentativesList', { params: this.params, id: this.activeAccount.id, token })
        this.loading = false
      },
      changeSearchText(val) {
        if (!val) {
          const query = { ...this.$route.query, page: 1, email: null }
          this.$router.push({ query })
        } else {
          const query = { ...this.$route.query, page: 1, email: val }
          this.$router.push({ query })
        }
      },
      getNewPage(page) {
        const query = { ...this.$route.query, page }
        this.$router.push({ query })
      },
      getNewData(val) {
        if (val === 0) {
          const query = { ...this.$route.query, page: 1, role: null }
          this.$router.push({query})
        } else {
          const role = val
          const query = { ...this.$route.query, page: 1, role }
          this.$router.push({query})
        }
      },
      // addBusinessAccount(id) {
        // if(this.clientRole ==='manager' || this.clientRole === 'finance') {
        //   this.$store.commit('Dialog/setData', { label: 'isEditable', data: true })
        // }
        // this.$store.commit('Dialog/setData', { label: 'userIdForAccount', data: id })
        // this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'BusinessAccountData' })
        // this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true }) 
      // },
  
      // getAllUsersList() {
      //   this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'GetUsersXLS' })
      //   this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
      // },
      // changeHolding(user) {
      //   this.$store.commit('Representatives/setData', { label: 'editUser', data: user })
      //   this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddEditUser' })
      //   this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
      // },
      tableRowClassName({ row }) {
        if (row.is_active === 0) {
          return 'el-table__row--deactivated';
        }
        return '';
      }
    },
  }
  </script>
  
  <style lang="scss">
  @import "@/assets/styles/helpers/variables";
  @import "@/assets/styles/helpers/mixin";
  
  .representatives-info {
    .container {
        display: flex;
        flex-direction: column;
    }
    .partner-column {
      div {
        max-width: 200px;
      }
      
    }
    padding: 5rem 0;

    &.locale-usa {
      .representatives-info__title {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 700;
        font-size: 3.2rem;
        line-height: 4rem;
      }
      .el-table__header, .el-table__body {
        font-family: 'Gilroy'; 
      }
      .el-table__row {
        .el-table__cell:nth-child(-n+2) {
          &.el-table__cell {
            font-weight: 700;
          }
        }
      }
    }
  
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2rem;
    }
    &__title {
      font-weight: 700;
      font-size: 3rem;
      line-height: 3.6rem;
      margin-bottom: 3rem;
    }
  
    &__download {
      flex-basis: 5rem;
      // border: 1px solid #d8d9da;
      border: none;
      background: none;
      margin-left: 1rem;
      background-image: url('../assets/icons/icon-xlsx-document.svg');
      background-repeat: no-repeat;
      background-position: center center;
    }
  
    &__filters {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4rem;
  
      .search {
        width: 100%;
      }
  
      .selector {
        margin-left: 1rem;
      }
    }
  
    &__table {
        margin-top: 4.7rem;
        .el-table__row {
  
        &--deactivated {
  
          td {
            background-color: rgba($redLight , .1);
          }
  
          &:hover {
  
            td {
              background-color: rgba($redLight , .2) !important;
            }
          }
        }
      }
  
      a {
        color: var(--primary);
      }
  
      &-button {
        background: var(--primary);
        border: 1px solid var(--primary);
        padding: 1rem 2rem;
        border-radius: 4px;
        color: #fff;
        width: 100%;
      }
  
      &-date {
        color: #9BA2AB;
        font-size: 1.4rem;
        line-height: 1.6rem;
      }
  
      &-change {
        color: $primary;
        cursor: pointer;
        display: flex;
        align-items: center;
  
        &>img {
          margin-right: 0.4rem;
        }
      }
  
      &-edit {
  
        .cell {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
  
        &__btn {
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          width: 80%;
          justify-content: center;
  
          svg {
            display: block;
            height: auto;
  
            path {
              transition: fill .4s;
            }
          }
  
          &:hover {
  
            svg {
  
              path {
                fill: $primaryHover;
              }
            }
          }
        }
      }
  
      &-deactivated {
        width: 100%;
        display: inline-block;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.9rem;
        color: $redLight;
        text-align: center;
      }
    }
  
    &__pagination {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
  
      .el-pagination .btn-next,
      .el-pagination .btn-prev,
      .el-pager li {
        background: transparent;
      }
    }
  
    @include below(993px) {
      padding-top: 32px;
  
      &__title {
        font-size: 24px;
        line-height: 1.4;
        margin-bottom: 24px;
      }
  
      &__filters {
        margin-bottom: 24px;
      }
    }
  
    @include below(576px) {
      padding-top: 24px;
  
      &__title {
        font-size: 20px;
        margin-bottom: 16px;
      }
  
      &__filters {
        flex-direction: column;
        margin-bottom: 16px;
  
        .search {
          width: 100%;
          margin-bottom: 8px;
        }
  
        .selector {
          width: 100%;
          margin-left: 0;
        }
      }
    }
    .el-table {
        .el-table__body-wrapper {

            .el-table__row {
                td:not(:last-child) {
                    .cell {
                        padding-left: 1rem;
                    }
                }
                td:last-child {
                    .cell {
                        padding-right: 1rem;
                    }
                }
            }
        }
    }
  }
  </style>
  