<template>
  <div v-loading="loading" class="applcation-page page">
    <div v-if="detail">
      <div class="applcation-page__info">
        <div class="applcation-page__status" :class="{ 'applcation-page__status--info': detail.status === 3 }">
          {{ lang==='ru'? statuses.ru[detail.status]:statuses.en[detail.status] }}
        </div>
        <div class="applcation-page__update">{{ $t('support_detail.updated') }}: {{
          fixDateFormatTime(detail.updated_at)
        }}</div>
      </div>
      <div class="applcation-page__title">
        <span v-if="detail.theme">{{ detail.theme.name }}: </span> <br>
        <span>{{ detail.name }}</span>
      </div>
      <div v-if="detail && detail.description" class="applcation-page__message" v-html="insertLineBreaks(detail.description)" />
      <!-- <div class="applcation-page__message">{{insertLineBreaks(detail.description) }}</div> -->
      <div class="applcation-page__date">{{ $t('support_detail.created') }}: {{ fixDateFormat(detail.created_at) }}
      </div>

      <div v-if="detail.attachments && detail.attachments.length" class="applcation-page__files">
        <div v-for="file in detail.attachments" :key="file.id" class="applcation-page__file"
          @click="downloadFile(file)">
          <div v-if="fileType(file.filename) === 'file'" class="applcation-page__file-content">
            <img src="@/assets/icons/icon-attach.svg" alt="" class="applcation-page__file-content-icon">
            <div>{{ file.filename }}</div>
          </div>

          <div class="applcation-page__file-content">
            <img v-if="fileType(file.filename) === 'file'" src="@/assets/icons/icon-attach.svg"
              class="applcation-page__file-content-icon">
            <img v-else-if="fileType(file.filename) === 'png'" src="@/assets/icons/icon-png.svg"
              class="applcation-page__file-content-icon">
            <img v-else-if="fileType(file.filename) === 'jpg'" src="@/assets/icons/icon-jpg.svg"
              class="applcation-page__file-content-icon">
            <img v-else-if="fileType(file.filename) === 'xlsx'" src="@/assets/icons/icon-xlsx-document.svg"
              class="applcation-page__file-content-icon">
            <img v-else-if="fileType(file.filename) === 'docx'" src="@/assets/icons/icon-docx-document.svg"
              class="applcation-page__file-content-icon">
            <img v-else-if="fileType(file.filename) === 'pdf'" src="@/assets/icons/icon-pdf-document.svg"
              class="applcation-page__file-content-icon">
            <div>{{ file.filename }}</div>
          </div>
        </div>
      </div>

      <div class="applcation-page__chat">
        <div class="applcation-page__chat-header">{{ $t('support_detail.messages') }}</div>
        <div v-if="messagesList && messagesList.length" class="applcation-page__chat-body">
          <div v-for="message in messagesList" :key="message.id" class="applcation-page__chat-message-row"
            :class="{ 'applcation-page__chat-message-row--highlighted': !message.manager }">
            <div class="applcation-page__chat-message"
              :class="{ 'applcation-page__chat-message--highlighted': !message.manager }">
              <div class="applcation-page__chat-message-top">
                <span class="applcation-page__chat-message-name">
                  {{ !message.manager ? activeAccount.legal_name : message.manager }}
                </span>
                <span class="applcation-page__chat-message-time">{{ fixDateFormatTime(message.created_at) }}</span>
              </div>
              <div class="applcation-page__chat-message-text" v-html="messageText(message.text)" />
              <div v-if="message.attachments && message.attachments.length" class="applcation-page__chat-message-files">
                <div v-for="file in message.attachments" :key="file.id" class="applcation-page__chat-message-file"
                  @click="downloadFile(file)">
                  <img v-if="fileType(file.filename) === 'file'" src="@/assets/icons/icon-attach.svg"
                    class="applcation-page__file-content-icon">
                  <img v-else-if="fileType(file.filename) === 'png'" src="@/assets/icons/icon-png.svg"
                    class="applcation-page__file-content-icon">
                  <img v-else-if="fileType(file.filename) === 'jpg'" src="@/assets/icons/icon-jpg.svg"
                    class="applcation-page__file-content-icon">
                  <img v-else-if="fileType(file.filename) === 'xlsx'" src="@/assets/icons/icon-xlsx-document.svg"
                    class="applcation-page__file-content-icon">
                  <img v-else-if="fileType(file.filename) === 'docx'" src="@/assets/icons/icon-docx-document.svg"
                    class="applcation-page__file-content-icon">
                  <img v-else-if="fileType(file.filename) === 'pdf'" src="@/assets/icons/icon-pdf-document.svg"
                    class="applcation-page__file-content-icon">
                  <div>{{ file.filename }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="applcation-page__chat-form">
          <div class="applcation-page__chat-form-box">
            <div class="applcation-page__messages-new">
              <label class="applcation-page__messages-attach">
                <img src="@/assets/icons/icon-attach.svg" alt="">
                <input ref="fileupload" id="files" type="file" @change="checkFile">
              </label>
              <textarea name="message" :placeholder="$t('support_detail.textarea_placeholder')" v-model="message"
                @keydown.enter="sendMessage" />

            </div>
            <ButtonElement class="support-page__new-application" :text="$t('button_props.send')" view="empty"
              :disabled="!message" @click-button="sendMessage" />
          </div>

          <div v-if="filesArr && filesArr.length" class="applcation-page__chat-files">
            <div v-for="(item, idx) in filesArr" :key="idx" class="applcation-page__chat-files-item">
              <button class="applcation-page__chat-files-delete" @click="deleteFile(item.id)">
                <img src="@/assets/icons/icon-delete.svg" alt="">
              </button>
              <img v-if="item.src" :src="item.src">
            </div>
          </div>
        </div>
      </div>
      <div v-if="detail.status !== 4" class="applcation-page__footer">
        <ButtonElement class="support-page__new-application" :text="$t('button_props.close_request')"
          @click-button="closeApplication" />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/ButtonElement";

import fixDateFormat from '@/mixin/fixDate'

export default {
  name: 'SupportDetail',
  components: {
    ButtonElement,
  },
  mixins: [fixDateFormat],
  data() {
    return {
      lang: this.$i18n._vm.locale,
      loading: false,
      message: '',
      filesArr: [],
      messagesList: null,
      statuses: {
       'ru': {
          1: "Открыт",
          2: "В работе",
          3: "Требуется информация",
          4: "Закрыт"
        },
        'en': {
          1:  "Open",
          2:  "In processing",
          3: "Information required",
          4: "Closed"
        },
      }
       
      
    }
  },
  computed: {
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    detail() {
      return this.$store.state.Support.detailApplication
    },
    applicationId() {
      return this.$route.params.id
    },
    getLang() {
      let language = this.$i18n._vm.locale
      return language
    }
  },
  watch: {
    getLang: {
      deep: true,
      handler(val) {
        this.dataPickerforceRerender(val)
      }
    },
    detail(val) {
      if (val) {
        this.messagesList = [...val.comments]
      }
    }
  },
  created() {
    this.getDetail()
    if (this.detail && this.detail.comments && this.detail.comments.length) {
      this.messagesList = [...this.detail.comments]
    }
  },
  methods: {
    dataPickerforceRerender(value) {
      this.lang = value
    },
    messageText(text) {
      const linkReg = /(https?:\/\/|ftps?:\/\/|www\.)((?![.,?!;:()]*(\s|$))[^\s]){2,}/gim;
      const subst = '<a href="$&" target="_blank">$&</a>';
      return text.replace(/\n\n/gi, ' <br> ').replace(linkReg, subst);
    },
    getDetail() {
      this.$store.dispatch('Support/getDetailApplication', this.applicationId)
    },
    resetResize() {
      const elem = document.getElementById('message')
      if (elem) elem.style.height = 'auto'
    },
    checkFile(e) {
      const files = e.target.files
      if (files && files.length) {
        let reader = new FileReader()
        files.forEach((el, idx) => {
          const fileType = el.type
          if (
            fileType === 'image/jpeg' ||
            fileType === 'image/jpg' ||
            fileType === 'image/png' ||
            fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            fileType === 'application/pdf'
          ) {
            reader.onload = (e) => {
              this.filesArr.push({ id: idx + el.name, name: el.name, src: e.target.result, type: 'image', file: el })
              this.$refs.fileupload.value = null;
            }
            reader.readAsDataURL(el)
          } else {
            this.$notify({
              message: this.$t('notify.message'),
              type: 'error'
            });
          }
        })
      }
    },
    deleteFile(id) {
      this.filesArr = this.filesArr.filter(el => el.id !== id)
    },
    sendMessage() {
      if (this.message) {
        this.loading = true
        const formData = new FormData()
        formData.append('text', this.message)
        if (this.filesArr && this.filesArr.length) {
          this.filesArr.forEach(el => {
            formData.append('files[]', el.file)
          })
        }
        const data = {
          id: this.applicationId,
          data: formData
        }
        this.$store.dispatch('Support/addCommentInApplication', data)
          .then(() => {
            this.messagesList.push({
              text: this.message,
              user_id: this.$store.state.Auth.user.id,
              id: Date.now(),
              created_at: Date.now(),
            })
            this.message = ''
            this.filesArr = []
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    closeApplication() {
      this.$store.dispatch('Support/closeApplication', this.applicationId)
        .then(() => {
          this.$router.push('/support')
        })
    },
    fileType(file) {
      const xlsTypes = ['xls', 'xlsx']
      const docTypes = ['doc', 'docx', 'msword']
      const fileExpantion = file.split('.').slice(-1)[0]
      if (xlsTypes.includes(fileExpantion)) {
        return 'xlsx'
      } else if (docTypes.includes(fileExpantion)) {
        return 'docx'
      } else if (fileExpantion === 'pdf') {
        return 'pdf'
      } else if (fileExpantion === 'png') {
        return 'png'
      } else if (fileExpantion === 'jpg') {
        return 'jpg'
      }
      return 'file'
    },
    downloadFile(file) {
      this.$store.dispatch('Support/downloadApplicationFile', file)
    },
    insertLineBreaks(text) {
      let newstr = text
      const re = /\n/g
      newstr = text.replace(re, '<br>');
      return newstr
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";


.applcation-page {
  position: relative;

  &__info {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__status {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-bottom: 1.8rem;
    background: #E6EAF2;
    color: #646D79;
    border-radius: 4px;
    padding: .2rem .8rem;
    width: max-content;

    &--info {
      color: #5A6EB6;
      background: #E5EDFC;
    }
  }

  &__update {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.4rem;
    text-align: right;
  }

  &__title {
    margin-bottom: 2.4rem;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 2.8rem;
    max-width: 57rem;
  }

  &__message {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
    max-width: 57rem;
    margin-bottom: 2rem;

    p {
      margin-bottom: 2rem;
    }
  }

  &__date {
    color: #7F858D;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-bottom: 3rem;
  }

  &__files {
    margin-bottom: 3rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__file {
    margin-right: 5rem;
    cursor: pointer;
  }

  &__file-content {
    display: flex;
    align-items: center;

    &-icon {
      flex-shrink: 0;
      margin-right: 2rem;
    }
  }

  &__chat {
    border: 1px solid #E6EAF2;
    box-shadow: 0px 5px 10px rgba(158, 158, 158, 0.1);
    border-radius: 6px;
    margin-bottom: 4rem;
  }

  &__chat-header {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.3rem;
    padding: 3.2rem 3.2rem 2.4rem 3.2rem;
    border-bottom: 1px solid #E6EAF2;
  }

  &__chat-body {
    padding: 3.2rem 4rem;
  }

  &__chat-message {
    background: #EDEFF3;
    border-radius: 10px;
    padding: 1.6rem;
    max-width: 71rem;

    &-row {
      margin-bottom: 1.6rem;
      display: flex;

      &--highlighted {
        justify-content: flex-end;
      }
    }

    &-top {
      display: flex;
      align-items: center;
      margin-bottom: .9rem;
    }

    &-name {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 1.9rem;
      display: block;
      margin-right: .8rem;
    }

    &-time {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: #646D79;
      opacity: 0.6;
    }

    &-text {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.9rem;
    }

    &-files {
      padding-top: 1rem;
    }

    &-file {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &--highlighted {
      background: $primary;
      color: #fff;

      .applcation-page__chat-message-time {
        color: #E6EAF2;
      }
    }
  }

  &__chat-form {
    padding: 3.2rem;
  }

  &__chat-form-box {
    display: flex;
    flex-direction: column;

    .button {
      flex-shrink: 0;
      max-width: 50%;
    }
  }

  &__messages-new {
    position: relative;
    width: 100%;

    textarea {
      border: 1px solid #D1D4D8;
      border-radius: 2px;
      width: 100%;
      height: 13rem;
      resize: none;
      padding: 1.5rem 5rem 1.5rem 1.5rem;

      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: #9BA2AB;
      }
    }
  }

  &__messages-attach {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    cursor: pointer;

    input {
      display: none;
    }
  }

  &__chat-files {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 2rem;
  }

  &__chat-files-item {
    position: relative;

    img {
      max-width: 100%;
    }
  }

  &__chat-files-delete {
    background: none;
    border: none;
    position: absolute;
    right: 0;
  }

  &__footer {
    background: #F5F7FA;
    box-shadow: 0px 4px 12px rgba(192, 192, 192, 0.12);
    padding: 3rem 7rem;
    display: flex;
    justify-content: flex-end;
  }
}
@include below(769px) {
  .applcation-page {
    &__info {
      position: static;
      margin-bottom: 2.4rem;
    }
    &__update {
      text-align: left;
    }
    &__status {
      margin-bottom: .5rem;
    }
    &__chat-form-box {
      .button {

        max-width: 100%;
      }
    }
  }
}
</style>
